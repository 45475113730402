<style scoped>

	.iconBig {
		width: 3rem;
		height: 3rem;
	}

  #page {
    height: 100%;
    width: 100%;
  }

  #report_page {
    top: 42px;
    position: fixed;
    height: 100%;
    width: 100%;
  }

  .doc {
      width: 100%;
      height: 100%;
  }

  #select-receipts {
    height: 16rem;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 9px;
  }

</style>

<template>
	<div id="page">
		<header class="mui-bar mui-bar-nav">
			<a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
			<h1 id="title" class="mui-title">{{title}}</h1>
			<span class="mui-btn mui-btn-white mui-btn-link mui-btn-nav mui-pull-right" v-on:tap='toggle_select_receipts()'>Send</span>
    </header>
		<div id="report_page">
      <template v-if="task.reportURL">
        <!-- <iframe class="doc" :src="`https://docs.google.com/gview?url=${task.reportURL}&embedded=true`"></iframe> -->
        <iframe class="doc" :src="`https://view.officeapps.live.com/op/embed.aspx?src=${task.reportURL}&embedded=true`"></iframe>
        <!-- <iframe class="doc" :src="reportURL"></iframe> -->
      </template>
      <template v-else>
        <div class="mui-row" style="position: absolute; top: 20%; margin: 1rem;">
          <h2 style="left: .2rem;">Please wait for a couple of minutes to view the report</h2>
        </div>
      </template>
    </div>

    <div id="select-receipts" class="mui-popover mui-card">
      <div class="mui-card-header">
        <h3>Select Receipts</h3>
      </div>
      <div class="mui-card-content">
        <ul class="mui-table-view" style="margin-top: 0.5rem;">
          <li class="mui-table-view-cell" v-for="contact in contacts" :key="contact.email">
            <div class="mui-row mui-checkbox">
              <span class="mui-pull-left" style="font-weight: 700; color:darkblue">{{contact.role}}: {{contact.name}}</span>
              <input class="mui-pull-right" :name="contact.email" :value="contact" type="checkbox" checked  v-model="selectedContacts">
            </div>
            <div class="mui-row">
              <span class="mui-pull-left contact">{{contact.email}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="mui-card-footer">
        <div class="wrapper">
          <button type="button" class="tab-item mui-btn mui-btn-secondary choice" v-on:tap='toggle_select_receipts()'>
            <span class="tab-label">Cancel</span>
          </button>
          <button type="button" class="tab-item mui-btn mui-btn-primary choice" v-on:tap='tap_send()'>
            <span class="tab-label">OK</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import Vue from "vue";
  import moment from "moment";
  import mui from "@/assets/js/mui.min";
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import {toast, alert, confirm, prompt, enableScroll, sleep, popover } from "@/assets/js/api";

  export default {
    name: "Viewsend",
    data() {
      return {
        title: 'report',
        task: {},
        selectedContacts: [],
        busy: false,
        idling: false,
        error: false,
        statusText: '',
        statusTime: moment().format('HH:mm:SS'),
        reportURL: '',
        house: null,
      }
    },
    computed: {
      contacts() {
        return this.house? this.house.contacts : []
      },
    },
    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        fetchTaskDetails: 'Tasks/fetchDetails',
        generateReport: 'Tasks/generateReport',
        sendReport: 'Tasks/sendReport',
        resetReportService: 'HouseList/resetReportService',
        getReportURL: 'Tasks/getReportURL',
      }),

      async start_report_service() {
        this.idling = true;
        Vue.set(this.task, 'report_file', null);
        Vue.set(this.task, 'reportURL', null);
        const pending = await this.generateReport({
            task_id: this.task.id,
            auto_issue: this.task.title === 'inspections',
            contacts: this.selectedContacts,
          });
      },


      async tap_reset_report_service() {
        const res = await confirm('WOULD YOU REALLY TO RESTART REPORT SERVICE')
        if (res.index === 1) {
          await this.run(async () => {
            await this.resetReportService();
            const statusText = res.data.data.statusText || 'Unknown'
            this.statusText = 'ReportService: ' + statusText
            await this.start_report_service()
          })
        }
      },

      async run(func) {
        try {
          this.busy = true
          await func()
          return true;
        } catch (e) {
          console.log(e)
          toast(e.message || e)
          this.error = true;
          return false;
        } finally {
          this.busy = false
        }
      },

      async tap_send () {
        this.toggle_select_receipts()
        await this.sendReport({
              task_id: this.task.id,
              contacts: this.selectedContacts,
          });
        // this.$router.push(`/taskboard?task_id=${this.task.id}`)
      },

      toggle_select_receipts () {
        popover('select-receipts')
      },

      async init () {
        const {task_id, refresh } = this.$router.currentRoute.query
        if (!task_id) {
          throw new Error('task_id not found')
        }
        this.task = await this.fetchTaskDetails(task_id)

        if (this.task) {
          const house_id = this.task.house_id;
          this.house = await this.fetchHouseDetails(house_id)
        }

        if (refresh || this.task.status < 8 || !this.task.report_file) {
          this.statusText = 'Request to make report...'
          console.log('Request to make report...', this.task)
          await this.start_report_service()
        }
      },
    },
    async mounted() {
      await this.init()
      enableScroll('.mui-scroll-wrapper')
    }
  }

</script>
