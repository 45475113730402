<style scoped>
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 9px;
  }

  .function {
    width: 50%;
    margin: 0.5rem 25%;
    height: 2.5rem;
    margin: 4px;
    flex: 1;
    color: white;
  }

  .mui-scroll-wrapper {
    bottom: 4rem;
  }
</style>

<template>
  <div id="page" v-if="task">
    <header class="mui-bar mui-bar-nav">
      <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
      <h1 class="mui-title">{{title}}</h1>
    </header>
    <div class="mui-scroll-wrapper">
      <div class="mui-scroll">

        <MapLocator :house="house" />

        <div class="mui-content-padded" style="padding: .5em;">
          <span class="mui-badge mui-row" style="margin-bottom: .5rem;">Task description</span>
          <textarea class="mui-row" v-model="task.description" placeholder="Task description"
            style="height: 8rem; margin: 0; font-size: .9rem; padding: 0.1rem; overflow:auto;" readonly />
          <Gallary :task="task" category="description" />
        </div>

        <div class="mui-content-padded" style="padding: .5em;">
          <span class="mui-badge mui-row" style="margin-bottom: .5rem;">Summary of performance</span>
          <textarea class="mui-row" v-model="task.summary" placeholder="Summary of performance"
            style="height: 8rem; margin: 0; font-size: .9rem; padding: 0.1rem; overflow:auto;" />
          <Gallary :task="task" category="summary" file camera />
        </div>

        <ClaimExpanse :task="task" />

      </div>
    </div>
    <nav class="mui-bar mui-bar-tab wrapper">
      <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_hold()'>
        <span class="tab-label">HOLD</span>
      </button>
      <button type="button" class="tab-item mui-btn mui-btn-primary function" v-on:tap='tap_done()' v-if="task.title == 'maintenance'">
        <span class="tab-label">DONE</span>
      </button>
      <button type="button" class="tab-item mui-btn mui-btn-primary function" v-on:tap='tap_report()' v-else>
        <span class="tab-label">REPORT</span>
      </button>
    </nav>
  </div>
</template>

<script>

  import {mapGetters, mapActions, mapMutations } from 'vuex'
  import {toast, alert, confirm, prompt, enableScroll } from "@/assets/js/api";
  import Gallary from "@/components/Gallary"
  import MapLocator from "@/components/MapLocator"
  import ClaimExpanse from "@/components/ClaimExpanse"

  export default {
    name: "Finish",
    components: {
      Gallary,
      MapLocator,
      ClaimExpanse,
    },
    data() {
      return {
        house: {
          id: ''
        },
        task: null,
      }
    },
    computed: {
      title() {
        return this.task ? this.task.title : ''
      },
    },
    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        fetchTaskDetails: 'Tasks/fetchDetails',
      }),
      async tap_report() {
        this.$router.push(`/viewsend?task_id=${this.task.id}`)
      },

      async tap_done() {
        await this.back();
      },

      async tap_hold() {
        this.$router.push(`/todolist`)
      },

      async init() {
        const {task_id} = this.$router.currentRoute.query
        if (!task_id) {
          throw 'invalid task id'
        }
        this.task = await this.fetchTaskDetails(task_id)

        if (this.task) {
          const house_id = this.task.house_id;
          this.house = await this.fetchHouseDetails(house_id)
        }
      },

    },
    async mounted() {
      await this.init()
      enableScroll('.mui-scroll-wrapper')
    }
  }

</script>
