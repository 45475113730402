<style scoped>
  .tab-label {
    color: white
  }
</style>

<template>
		<div id="page" v-if="task">
			<header class="mui-bar mui-bar-nav">
        <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
				<h1 class="mui-title">HHS report</h1>
			</header>
			<div class="mui-content">
				<div class="mui-scroll-wrapper">
					<div class="mui-scroll">
            <MapLocator :house="house" />

            <div v-for="section in sections" :key="section.title">
              <div style="margin-top: .5rem;">
                <span class="mui-badge mui-pull-left" style="margin-left: .5rem;">{{section.title}}</span>
              </div><br />
              <ul class="mui-table-view">
                <li class="mui-table-view-cell"
                v-for="component in section.components" :key="component.title"
                :class="{active: !uploaded(component)}"
                v-on:tap='tap_component(section, component)'>
                  <div class="mui-slider-handle">
                    <svg class="icon mui-pull-left" aria-hidden="true">
                      <use xlink:href="#icon-dingweidaohang"></use>
                    </svg>
                    <div class="mui-pull-left" style="margin-left: 1rem; padding-left: 1rem;">
                        {{component.title}}
                    </div>
                    <div class="mui-pull-right" style="margin-left: 1rem; padding-left: 1rem;">
                      <p style="margin-right: 1.5rem;">{{component.reportIssues? 'Issues': ''}}</p>
                      <svg v-if="component.images && component.images.length !=0" class="icon" aria-hidden="true" style="margin-right: .5rem;">
                        <use xlink:href='#icon-jpg'></use>
                      </svg>
                      <svg v-if="component.note" class="icon" aria-hidden="true" style="margin-right:.5rem;">
                        <use xlink:href='#icon--biji'></use>
                      </svg>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

				</div>
			</div>
      <nav class="mui-bar mui-bar-tab wrapper">
        <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_sync()' diabled>
          <span class="tab-label">SYNC TO SERVER</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-primary function" v-on:tap='tap_report()' diabled>
          <span class="tab-label">REPORT</span>
        </button>
      </nav>
		</div>
</template>

<script>

  import {mapGetters, mapActions, mapMutations } from 'vuex'
  import {toast, alert, confirm, prompt, enableScroll } from "@/assets/js/api";
  import MapLocator from "@/components/MapLocator"

  export default {
    name: "HHSReport",
    components: {
      MapLocator,
    },
    data() {
      return {
        house: {},
        task: null,
      }
    },
    computed: {
      ...mapGetters({
        getResult: 'Tasks/getTaskResult',
      }),
      finished() {
        return true
      },
      sections() {
        return this.getResult(this.task.id) || []
      },
    },
    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        fetchTaskDetails: 'Tasks/fetchDetails',
        fetchTaskResult: 'Tasks/fetchTaskResult',
        syncTaskResult: 'Tasks/syncTaskResult',
      }),

      uploaded(c) {
        return !c.images || c.images.every(img => img.task_id)
      },

      pendingForSync() {
        if (!this.sections) {
          return false
        }
        return this.sections.some(section => {
          if (section.cmd) return true
          return section.components.some(component => {
            if (component.cmd) return true
            if (!this.uploaded(component)) return true
            return component.images.some(img => img.cmd)
          })
        })
      },

      async tap_sync() {
        await this.syncTaskResult(this.task.id)
      },

      async tap_report() {
        if (this.pendingForSync()) {
          toast('Please SYNC to server first')
        } else if (!this.finished) {
          toast('Please Complete all sections first')
        } else {
          const answer = await confirm('Are you sure all parts are ready for report?', 'Report', ['NO', 'YES'])
          if (answer.index === 1) {
            this.$router.push(`/viewsend?task_id=${this.task.id}&refresh=1`)
          }
        }
      },

      tap_component (section, component) {
        this.$router.push(`HHSComponent?task_id=${this.task.id}&path=${section.id}:${component.id}`)
      },

      async init () {
        const {task_id} = this.$router.currentRoute.query
        if (!task_id) {
          throw 'invalid task id'
        }
        this.task = await this.fetchTaskDetails(task_id)
        await this.fetchTaskResult(task_id)

        if (this.task) {
          const house_id = this.task.house_id;
          this.house = await this.fetchHouseDetails(house_id)
        }
      },
    },
    async mounted() {
      this.init()
      enableScroll('.mui-scroll-wrapper')
    }
  }

</script>
