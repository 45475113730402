
export const TaskStatus = {
  Scheduled: 0,
  Confirmed: 1,
  Performing: 2,
  Reporting: 7,
  Finished: 8,
  Done: 9,
  Archived: 10
};
