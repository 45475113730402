
import mui from "@/assets/js/mui.min.js";

export function enableScroll(locator) {
  mui(locator).scroll({
    bounce: false,
    indicators: true,
    deceleration: mui.os.ios ? 0.003 : 0.0009
  })
}

export function toast(msg) {
  mui.toast(msg);
}

export function alert(msg) {
  mui.alert(msg);
}

export function prompt(question, title, choices) {
  return new Promise((resolve, reject) => {
    mui.prompt(question, title, choices, resolve, reject);
  })
}

export function confirm(text, title, choices) {
  return new Promise((resolve, reject) => {
    mui.confirm(text, title, choices, resolve, reject);
  })
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function popover(id, show) {
  const verb = show ? 'show': typeof show != 'undefined'? 'hide' : 'toggle'
  mui(`#${id}`).popover(verb, document.getElementById(id))
}

export default {
  enableScroll,
  toast,
  alert,
  prompt,
  confirm,
  popover,
}

