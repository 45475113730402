<style scoped>
  .inspection-type {
    color: orangered
  }

  .tab-label {
    color: white
  }

  .mui-scroll-wrapper {
    bottom: 3.7rem;
  }

</style>

<template>
	<div id="create_page" v-if="task && rooms">
		<header class="mui-bar mui-bar-nav">
      <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
			<h1 class="mui-title">Regular inspection</h1>
		</header>
		<div class="mui-content">
			<div class="mui-scroll-wrapper">
				<div class="mui-scroll">
          <MapLocator :house="task.house" />

					<div class="mui-row">
  					<div @click.stop="tap_inspection_type()">
              <span class="inspection-type">{{inspectionTypeText}}</span>
            </div>
          </div>

          <div style="margin-top: .5rem;">
            <div class="mui-row">
              <span class="mui-badge mui-pull-left">Rooms</span>
              <span class="mui-icon material-icons mui-pull-right" style="color:blue;" aria-hidden="true" v-on:tap="tap_add_room()">add_circle_outline</span>
            </div>
            <ul class="mui-table-view">
              <li class="mui-table-view-cell" v-for="room in visibleRooms" :key="room.id" v-on:tap='tap_room(room)'>
                <div class="mui-slider-right mui-disabled">
                  <a class="mui-btn mui-btn-red" v-on:tap.stop='tap_room_delete(room,$event)'>DELETE</a>
                </div>
                <div class="mui-slider-left mui-disabled">
                  <a class="mui-btn mui-btn-blue" v-on:tap.stop='tap_room_rename(room,$event)'>RENAME</a>
                </div>
                <div class="mui-slider-handle middle" :class="{active: !roomSynced(room)}">
                  <svg class="icon mui-pull-left" aria-hidden="true">
                    <use v-bind="{'xlink:href':room.icon}"></use>
                  </svg>
                  <span class="mui-pull-left">{{room.name}}</span>
                  <span class="mui-icon material-icons mui-pull-right">navigate_next</span>
      						<span class="mui-pull-right" v-if="task_id">{{condition(room)}}</span>
                </div>
              </li>
            </ul>


          </div>

          <div class="mui-content-padded" style="padding: .5em;">
            <span class="mui-badge mui-row">Summary of performance</span>
            <textarea class="mui-row" v-model="task.summary" placeholder="Summary of performance"
              style="height: 8rem; margin: 0; font-size: .9rem; padding: 0.1rem; overflow:auto;" />
            <!--<Gallary :task="task" category="summary" file camera />-->
          </div>
				</div>
			</div>
		</div>

    <div id="add_room" class="mui-popover">
			<div class="mui-popover-arrow"></div>
      <div class="mui-scroll-wrapper" style="height: 55rem; margin: 0px;">
        <div class="mui-scroll">
          <ul class="mui-table-view">
            <li v-for="rt in roomTemplates" :key="rt.label" class="mui-table-view-cell" v-on:tap='tap_room_template(rt)'>
              <svg class="icon mui-pull-left" aria-hidden="true">
                <use :xlink:href="rt.xlink_href"></use>
              </svg>
              <span class="mui-pull-left" style="margin-left: 1rem;">
                {{rt.label}}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>


    <nav class="mui-bar mui-bar-tab wrapper">
      <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_sync()' :disabled="busy">
        <span class="tab-label">SYNC TO SERVER</span>
      </button>
      <button type="button" class="tab-item mui-btn mui-btn-primary function" v-on:tap='tap_report()'>
        <span class="tab-label">REPORT</span>
      </button>
    </nav>

	</div>
</template>

<script>

  import Vue from "vue";
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import mui from "@/assets/js/mui.min";
  import { toast, alert, confirm, prompt, popover, enableScroll } from "@/assets/js/api";
  import MapLocator from "@/components/MapLocator"
  import Gallary from "@/components/Gallary"

  export default {
    name: "Inspection",
    components: {
      MapLocator,
    },
    data() {
      return {
        task_id: null,
        task: null,
        roomTemplates: [{
          xlink_href: '#icon-jiajiabaojie-tubiao-',
          label: 'Bedroom',
        }, {
          xlink_href: '#icon-canting',
          label: 'Dinning Room',
        }, {
          xlink_href: '#icon-keting1',
          label: 'Living Room',
        }, {
          xlink_href: '#icon-youxiji--',
          label: 'Play Room',
        }, {
          xlink_href: '#icon-chufangyongju',
          label: 'Kitchen',
        }, {
          xlink_href: '#icon-weishengjian',
          label: 'Toilet',
        }, {
          xlink_href: '#icon-xiyiji',
          label: 'Laundry',
        }, {
          xlink_href: '#icon-tingcheruku',
          label: 'Garage',
        }, {
          xlink_href: '#icon-keting1',
          label: 'Lounge',
        }, {
          xlink_href: '#icon-men',
          label: 'Entrance/Hall',
        }, {
          xlink_href: '#icon-Other',
          label: 'General',
        }, {
          xlink_href: '#icon-Other',
          label: 'Other',
        }],
      }
    },
    computed: {
      ...mapGetters({
        taskSynced: 'Tasks/taskSynced',
        sectionSynced: 'Tasks/sectionSynced',
        componentSynced: 'Tasks/componentSynced',
        busy: 'Tasks/busy',
        getResult: 'Tasks/getTaskResult',
      }),
      rooms() {
        return this.getResult(this.task_id) || []
      },
      visibleRooms() {
        return this.rooms.filter(room => room && room.cmd != 'delete')
      },
      inspectionTypeText() {
        return (this.task && this.task.subtitle == 'moving_inout')
          ? 'This is a moving in/out inspection report'
          : 'This is a regular inspection report'
      },
      pendingForSync() {
        if (!this.task || !this.rooms) {
          return false
        }
        return this.rooms.some(room => room.cmd)
      },
      finished() {
        if (!this.sections) {
          return true
        }
        return this.sections.every(section => !section.components || section.components.every(a => !!a.status))
      },
      sections() {
        return this.rooms
      },
    },
    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        fetchTaskDetails: 'Tasks/fetchDetails',
        fetchTaskResult: 'Tasks/fetchTaskResult',
        syncTaskResult: 'Tasks/syncTaskResult',
        addNewRoom: 'HouseList/addRoom',
      }),
      ...mapMutations({
        setDetails: 'Tasks/setDetails',
        roomAdded: 'Tasks/sectionAdded',
        roomModified: 'Tasks/sectionModified',
        roomDeleted: 'Tasks/sectionDeleted',
        addNewRoomForInspection: 'Tasks/addRoomForInspection',
        resetReportFile: 'Tasks/resetReportFile'
      }),

      roomSynced(room) {
        return this.sectionSynced(room)
      },

      tap_add_room() {
        popover('add_room', true)
      },

      async tap_room_template(rt) {
        popover('add_room', false)

        let name = rt.label
        if (name.indexOf('Other') != -1) {
          const e = await prompt('', '', 'Name', ['CANCEL', 'OK']);
          if (e.index == 1) {
            name = e.value.trim()
          }
        }
        const newRoom = await this.addNewRoom({
          house_id: this.task.house_id,
          name,
          icon: rt.xlink_href,
        });
        this.addNewRoomForInspection({
          task_id: this.task.id,
          room: newRoom,
        })
      },

      async tap_room_delete(room, el) {
        if (el) {
          var li = el.currentTarget.parentNode.parentNode
          mui.swipeoutClose(li)
        }
        const e = await confirm('DELETE', `Are you sure to delete this room of ${room.name}`, ['NO', 'YES']);
        if (e.index == 1) {
          this.roomDeleted(room)
        }
      },

      async tap_room_rename(room, el) {
        if (el) {
          var li = el.currentTarget.parentNode.parentNode
          mui.swipeoutClose(li)
        }
        const e = await prompt('Rename this room:', room.name, 'Name', ['CANCEL', 'OK']);
        if (e.index == 1) {
          room.name = e.value.trim()
          this.roomModified(room)
        }
      },

      async tap_room(room) {
        this.$router.push(`/checkroom?task_id=${this.task_id}&house_id=${this.house_id}&room_id=${room.id}`)
      },

      condition(room) {
        if (!room || !room.components) {
          return 'Invalid'
        }
        return room.components.every(a => !!a.status)? 'Complete' : 'Incomplete'
      },

      complete(room) {
        if (!room || !room.components) {
          return 'Invalid'
        }
        return room.components.every(a => !!a.status)
      },

      async tap_sync() {
        // console.log('tap_sync', this.task.id)
        await this.syncTaskResult(this.task.id)
      },

      async tap_report() {
        if (!this.taskSynced(this.task)) {
          toast('Please SYNC to server first')
        } else if (!this.taskSynced(this.task)) {
          toast('Please Complete all rooms first')
        } else {
          const answer = await confirm('Are you sure all parts are ready for report?', 'Report', ['NO', 'YES'])
          if (answer.index === 1) {
            this.resetReportFile(this.task.id)
            Vue.set(this.task, 'report_file', null);
            this.$router.push(`/viewsend?task_id=${this.task.id}`)
          }
        }
      },

      async tap_inspection_type() {
        const e = await confirm('', `Is it a moving in/out report ?`, ['NO', 'YES'])
        if (e.index == 0) {
          this.setDetails({ id: this.task.id, subtitle: 'normal'})
          Vue.set(this.task, 'subtitle', 'normal')
        } else if (e.index == 1) {
          this.setDetails({ id: this.task.id, subtitle: 'moving_inout'})
          Vue.set(this.task, 'subtitle', 'moving_inout')
        }
      },

      async init () {
        const {task_id} = this.$router.currentRoute.query
        if (!task_id) {
          throw 'invalid task id'
        }
        this.task_id = task_id
        this.task = await this.fetchTaskDetails(task_id)
        await this.fetchTaskResult(task_id)

        if (this.task) {
          const house_id = this.task.house_id;
          await this.fetchHouseDetails(house_id)
        }
      },
    },

    async mounted() {
      await this.init()
      enableScroll('.mui-scroll-wrapper')
    }
  }

</script>
