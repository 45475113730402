import Vue from "vue";
import "@/plugins/vuetify";
import VueMoment from "vue-moment";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import authHelper from "@/helpers/auth";
import VueConfirmDialog from "vue-confirm-dialog";
import VueWorker from 'vue-worker'
import './registerServiceWorker'
import VueLocalforage from 'v-localforage'
import localforage from 'localforage'

Vue.config.productionTip = false;
Vue.use(VueMoment);

authHelper.initInterceptors();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

Vue.use(VueWorker)

Vue.use(VueLocalforage, {
  driver      : localforage.LOCALSTORAGE, // Force LOCALSTORAGE; same as using setDriver()
  name        : 'localImages',
  version     : 2.0,
  size        : 1 * 1024 *1024*1024, // Size of database, in bytes.
  storeName   : 'name_src', // Should be alphanumeric, with underscores.
  description : 'The dictionary for local images, key is name, value is DataURL'
})

