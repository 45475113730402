import axios from "axios";
import router from "@/router";

function initInterceptors() {
    // Add a request interceptor
    axios.defaults.baseURL = window.appConfig.baseURL;
    axios.defaults.docURL = window.appConfig.docURL;

    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("token");
            const account_id = localStorage.getItem("account_id");
            const account_type = localStorage.getItem("account_type");
            const company_id = localStorage.getItem("company_id");
            console.log({account_id, account_type, company_id});
            config.headers.token = token;
            config.headers.account_id = account_id;
            config.headers.account_type = account_type;
            config.headers.company_id = company_id;
            config.headers.is_mobile = "true";
            config.headers.app_version = "2.0"; // 获取当前版本号
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (response) => {
            // store.commit('Progress/decrement')
            return response;
        },
        (error) => {
            // store.commit('Progress/decrement')

            if (error.response.status === 401) {
                deleteUser();
                router.push("/");
            }

            return Promise.reject(error);
        }
    );
}

function isAuthenticated() {
  const tokenString = localStorage.getItem("token");
  return !!tokenString
}

function getUser() {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);

    return user || {};
}

function setUser(payload) {
    const user = {
        token: payload.headers.token,
        accountId: payload.headers.account_id,
        companyId: payload.data.data.company_id,
        accountType: payload.data.data.type,
    };

    localStorage.setItem("user", JSON.stringify(user));
}

function deleteUser() {
  localStorage.removeItem("user");
}


export default {
    initInterceptors,
    setUser,
    getUser,
    isAuthenticated,
};
