<template>
  <div class="mui-content-padded" v-if="house">
    <MapLocator :house="house" />
    <ul class="mui-table-view" style="margin-top: 0.5rem;">
      <li class="mui-table-view-cell">
        <input class="mui-pull-left description" style="width: 100%;" type="text" v-model="house.note" placeholder="House Note">
      </li>
    </ul>

    <ul class="mui-table-view" style="margin-top: 0.5rem;">
      <li class="mui-table-view-cell mui-collapse" v-for="contact in contacts" :key="contact.email">
        <a class="mui-row mui-navigate-right" href="#">
          <span class="mui-pull-left">{{contact.role}}</span>
          <span class="mui-pull-right" style="font-weight: 700; color:darkblue">{{contact.name}}</span>
        </a>
        <div class="mui-collapse-content" style="margin-top: .5rem;">
          <a :href="smsLinkForTenant(contact)" style="display: flex;">
            <svg style="width:2rem; height: 2rem; margin-right: 1rem;" class="icon mui-pull-right" aria-hidden="true">
              <use xlink:href="#icon--wenjian"></use>
            </svg>
            <span class="contact">{{contact.phone}}</span>
          </a>
          <a :href="'mailto:' + contact.email" style="display: flex;">
            <svg style="width:2rem; height: 2rem; margin-right: 1rem;" class="icon mui-pull-right" aria-hidden="true">
              <use xlink:href="#icon--youjian"></use>
            </svg>
            <span class="contact">{{contact.email}}</span>
          </a>
        </div>
      </li>
    </ul>

    <ul class="mui-table-view" style="margin-top: 0.5rem;">
      <li class="mui-table-view-cell mui-collapse" v-if="issues && issues.length">
        <a class="mui-row mui-navigate-right" href="#">
          <span class="contact mui-pull-left" style="font-weight: 700; color:darkblue">
            Issues:<span class="mui-badge" style="margin-bottom: .3rem; margin-top: .5rem;">{{issues.length}}</span>
          </span>
        </a>
        <div class="mui-row">
          <ul class="mui-collapse-content mui-table-view" style="padding-left: 1.5rem;">
            <li class="mui-table-view-cell" v-for="issue in issues" :key="issue.id">
              <div class="mui-row">
                <span class="mui-pull-left">
                  {{issue.section}}
                </span>
                <span class="mui-pull-right">
                  {{issue.component}}
                </span>
              </div>
              <div class="mui-row">
                <span class="mui-pull-left" style="font-style: italic; font-size: small;">
                  {{issue.description}}
                </span>
              </div>
              <div class="mui-table-view-cell" v-for="action in issue.issue_action" :key="action.label">
                <div class="mui-row">
                  <span class="mui-badge mui-pull-left">
                    {{action.status}}
                  </span>
                  <span class="mui-badge mui-pull-right">
                    {{action.created_at}}
                  </span>
                </div>
                <div class="mui-row">
                  <span class="mui-badge mui-pull-left">
                    {{action.assignee}}
                  </span>
                  <span class="mui-badge mui-pull-right">
                    {{action.action}}
                  </span>
                </div>
                <div class="mui-row">
                  <span class="mui-pull-left" style="font-style: italic;font-size: small;">
                    {{action.effect}}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import Vue from "vue";
  import moment from "moment";
  import MapLocator from "@/components/MapLocator"
  import {toast, alert, confirm, prompt } from "@/assets/js/api";

  export default {
    name: "HouseGlance",
    components: {
      MapLocator,
    },
    props: {
      house: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
      }
    },
    computed: {
      smsLinkForOwner() {
        if (!this.house) {
          return ''
        }
        const message = `Dear ${this.house.owner_firstname}, for maintenance purpose we are organizing a ...`
        return `sms:${this.house.owner_phone}&body=${message}`
      },
      smsLinkForTenant() {
        return (contact) => {
        const message = `
        Hi there,

        Our inspectors Alan and Ray will visit your house  for regular inspection.
        If you won't be home it doesn't matter, cause we do have the spare keys.
        If you wish to stay, that will also be fine.

        Please reply 【OK】to this message, we assume you have got the message.
        They will visit between 9:30 am to 4:30 pm.

        Thanks for your cooperation.
        If you have anyproblem，please text me！Have a nice day！

        Unity property management
        `;
        return `sms:${contact.phone}&body=${message}`
      }},
      issues() {
        return this.house.issue || []
      },
      contacts() {
        return this.house.contacts || []
      },
    },
    methods: {
      tap_map() {
        const ref = `https://www.google.co.nz/maps/place/${this.house.num} ${this.house.street}, ${this.house.suburb}`
        window.location.href = ref
      },

      async save_house_note() {
        this.house.note = this.house_note;
      },

    },
  }
</script>

<style scoped>
  a {
    width: 100%;
    margin-left: 1rem;
    color: darkblue;
    font-size: small;
    text-underline-position: auto;
  }

  ul {
    list-style-type: disc;
  }
</style>