<style lang="scss">
</style>

<template>
  <div id="setDueDate" class="mui-popover" style="width: 100%; height: 27rem; left: 0; bottom: 0rem;">
  	<div class="mui-card-header">
      <span class="mui-pull-left" style="font-weight: bolder">{{title}}</span>
      <span class="mui-icon material-icons mui-pull-right" style="color: secondary;" v-on:tap='onSelected()'>close</span>
    </div>
  	<div class="mui-card-content" style="height: 23.7rem; top: 0">
      <div class="mui-scroll-wrapper" style="top: 0">
        <div class="mui-scroll">
          <ul class="mui-table-view">
            <li v-for="dueDate in dueDates" :key="dueDate.timestamp" class="mui-table-view-cell" v-on:tap='onSelected(dueDate.timestamp)'>
              <span class="mui-icon material-icons mui-pull-left" style="color:navy;">event</span>
              <span class="mui-pull-left" style="margin-left: 1rem;">{{dueDate.text}}</span>
            </li>
          </ul>
          <div class="mui-table-view-cell" style="display: flex;  align-items: center; padding: 30px 11px; 15px;">
            <span class="mui-icon material-icons mui-pull-left" style="color:navy;">event_note</span>
            <span class="mui-pull-left" style="margin-left: 1rem;">Another date</span>
            <input type="date" class="mui-pull-right" style="width: 55%; margin: 0 0 0 12px;" v-model="customDate" v-on:change="onSelected(customTimestamp);">
          </div>
        </div>
      </div>
    </div>
  	<div class="mui-card-footer mui-table-view" style="border-top: 1px solid; border-radius: 0;">
    </div>
  </div>
</template>

<script>

  import Vue from "vue";
  import moment from "moment";
  import { popover } from "@/assets/js/api";

  export default {
    name: "selectDueDate",
    data() {
      return {
        today: moment().startOf('day').add(12, 'h'),
        customDate: moment().startOf('day').add(12, 'h').format('YYYY-MM-DD'),
        title: "Select due date",
        callback: null,
      }
    },
    computed: {
      dueDates() {
        const dates = []
        let date = this.today
        for (let i = 0; i < 14; i++) {
          dates.push({
            text: `${date.format('DD MMM')}, ${date.format('dddd')}, ` + (i === 0? 'Today': i === 1? 'Tomorrow': (i+1) + ' days later'),
            timestamp: date.unix()
          })
          date.add(1, 'd');
        }
        return dates
      },
      customTimestamp() {
        console.log({customDate: this.customDate})
        const timestamp = moment(this.customDate).unix();
        return timestamp
      },
    },
    methods: {
      async onSelected(timestamp) {
        if (this.callback) {
          await this.callback(timestamp)
        }
        popover('setDueDate', false)
      },
      show(title) {
        return new Promise((resolve, reject) => {
          this.title = title
          this.callback = resolve
          popover('setDueDate', true)
        })
      }
    },
  };
</script>

