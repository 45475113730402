<template>
  <div id="signin_page">
    <header class="mui-bar mui-bar-nav">
      <h1 class="mui-title">Sign In</h1>
    </header>
    <div class="mui-content">
      <div class="mui-content-padded">
        <p>Sig in to your Account</p>
      </div>
      <div class="mui-card">
        <div class="mui-text-center">
          <img id="logo" style="width: 150px;" src="@/assets/logo.png" />
        </div>
        <div class="mui-card-content">
          <div class="mui-input-row">
            <label>
              <svg class="icon mui-pull-left" aria-hidden="true">
                <use xlink:href="#icon-wodezhanghao" />
              </svg>
            </label>

            <input type="text" placeholder="account" v-model="username" />
          </div>
          <div class="mui-input-row">
            <label>
              <svg class="icon mui-pull-left" aria-hidden="true">
                <use xlink:href="#icon-mima" />
              </svg>
            </label>
            <input type="password" placeholder="Password" v-model="password" />
            <a
              style="font-size: 10px; float: left; padding: 10px 0px;"
              class="mui-btn mui-btn-link"
              v-on:tap="tap_forgot()"
            >
              Forgot Password
            </a>
          </div>
          <br />
          <button
            style="width: 90%; margin: 10px 15px;"
            type="button"
            class="mui-btn mui-btn-blue"
            v-on:tap="tap_signin()"
          >Sign In</button>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toast} from "@/assets/js/api";
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "SignIn",
  components: {},
  data() {
    return {
      username: "",
      password: ""
    };
  },
  computed: {
    ...mapGetters({
      token: 'Accounts/token',
      account: 'Accounts/account'
    })
  },
  methods: {
    ...mapActions({
      login: 'Accounts/signIn',
    }),

    async tap_signin() {
      if (!this.username || !this.password) {
        toast("Invalid account or password");
        return;
      }
      const param = {
        account: this.username,
        password: this.password
      }
      await this.login(param)
      this.$router.push("/todolist")
    },

    tap_forgot() {
      toast('Later');
    },
  },

  mounted() {
    if (this.token && this.account) {
      // Jumpto todolist to
      this.$router.push("/todolist")
    }
  },
};
</script>


<style scoped>

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.mui-input-row label {
  width: 15%;
}

.mui-input-row label ~ input {
  float: left;
  width: 60%;
}
</style>

