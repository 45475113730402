<template>
	<div id="room_page" v-if="task && room &&  house_room_ids.room_id">
		<header class="mui-bar mui-bar-nav">
      <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
			<h1 class="mui-title">{{title}}</h1>
		</header>
		<div class="mui-content">
			<div style="text-align:center">
				<svg style="width: 2rem;height: 2rem;" aria-hidden="true">
					<use v-bind="{'xlink:href':room.icon}"></use>
				</svg>
			</div>

			<div class="mui-scroll-wrapper">
				<div class="mui-scroll">
					<div class="mui-row" style="margin-top: .5rem;">
						<span class="mui-badge">Areas</span>
            <span class="mui-icon material-icons mui-pull-right" style="color:blue;" aria-hidden="true" v-on:tap="tap_add_area()">add_circle_outline</span>
					</div>

					<ul class="mui-table-view" style="margin-top: .5rem;">

						<li class="mui-table-view-cell" v-for="area in visibleAreas" :key="area.id" v-on:tap="tap_item(area)">
							<div class="mui-slider-right mui-disabled">
								<a class="mui-btn mui-btn-red" v-on:tap.stop='tap_delete_area(area,$event)'>DELETE</a>
              </div>
              <div class="mui-slider-left mui-disabled">
                <a class="mui-btn mui-btn-blue" v-on:tap.stop='tap_rename_area(area,$event)'>RENAME</a>
              </div>
              <div class="mui-slider-handle middle" :class="{active: !areaUploaded(area)}">
              		<svg class="icon mui-pull-left" aria-hidden="true">
  									<use v-bind="{'xlink:href':area.icon}"></use>
  								</svg>
                  <span class="mui-pull-left">{{area.area|| area.name}}</span>
                  <span class="mui-icon material-icons mui-pull-right">navigate_next</span>
                  <span class="mui-pull-right mui-icon material-icons" v-if="area.images && area.images.length > 0">camera</span>
                  <span class="mui-pull-right mui-icon material-icons" v-if="area.note">text_snippet</span>
									<span class="mui-pull-right">{{area.status}}</span>
							</div>
						</li>
					</ul>
				</div>
			</div>

		</div>

		<div id="add_area" class="mui-popover">
			<div class="mui-popover-arrow"></div>
			<div class="mui-scroll-wrapper" style="height: 55rem; margin: 0px;">
				<div class="mui-scroll">
					<ul class="mui-table-view">
						<li v-for="at in areaTemplates" :key="at.label" class="mui-table-view-cell" v-on:tap="tap_area_template(at)">
							<svg class="icon mui-pull-left" aria-hidden="true">
								<use :xlink:href="at.xlink_href"></use>
							</svg>
							<a>
								{{at.label}}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
    <nav class="mui-bar mui-bar-tab wrapper">
      <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_sync()' :disabled="busy">
        <span class="tab-label">UPLOAD PHOTOS</span>
      </button>
    </nav>

	</div>
</template>

<script>
  import Vue from "vue";
  import mui from "@/assets/js/mui.min";
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import {toast, alert, confirm, prompt, enableScroll,popover } from "@/assets/js/api";

  export default {
    name: "CheckRoom",
    data() {
      return {
        task_id: 0,
        house_room_ids: {
          house_id: 0,
          room_id: 0,
        },
        task: null,
        areaTemplates: [
          { xlink_href: '#icon-yugang-', label: 'Bath' },
          { xlink_href: '#icon-chuanglian', label: 'Blinds/Curtains' },
          { xlink_href: '#icon-pudiban', label: 'Floors/Fi.coverings' },
          { xlink_href: '#icon-taideng', label: 'Lights/Power points' },
          { xlink_href: '#icon-shuzhuangtai', label: 'Mirror/cabinet' },
          { xlink_href: '#icon-xizao', label: 'Shower' },
          { xlink_href: '#icon-matong', label: 'Toilet(WC)' },
          { xlink_href: '#icon-zhuanqiang', label: 'Walls/Doors' },
          { xlink_href: '#icon-xishoutai', label: 'Wash Basin' },
          { xlink_href: '#icon-chuangkou', label: 'Windows' },
          { xlink_href: '#icon-dengzi', label: 'Bench' },
          { xlink_href: '#icon-reshuiqi', label: 'Stove' },
          { xlink_href: '#icon-kaoxiang', label: 'Oven' },
          { xlink_href: '#icon-youyanji', label: 'Fans/Extractors' },
          { xlink_href: '#icon--yuechi', label: 'Key' },
          { xlink_href: '#icon-qita', label: 'Furniture' },
          { xlink_href: '#icon-qita', label: 'Rubbish Bin' },
          { xlink_href: '#icon-qita', label: 'Area' },
          { xlink_href: '#icon-Other', label: 'Other' },
        ]
      }
    },
    computed: {
      ...mapGetters({
        getHouse: 'HouseList/getHouse',
        getTask: 'Tasks/getTask',
        areaUploaded: 'Tasks/componentUploaded',
        busy: 'Tasks/busy',
        imageURL: 'Tasks/imageURL',
        getTaskResult: 'Tasks/getTaskResult',
      }),
      house() {
        return this.getHouse(this.houseId)
      },
      rooms() {
        if (!this.task) return null
        return this.getTaskResult(this.task.id) || this.house.room
      },
      room() {
        return this.rooms? this.rooms.find(r => r.id == this.house_room_ids.room_id) : null
      },
      areas() {
        return this.room? this.room.components: [];
      },
      visibleAreas() {
        return this.areas.filter(a => a.cmd != 'delete')
      },
      title() {
        return this.room? this.room.name : 'Room'
      },
    },
    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        fetchTaskDetails: 'Tasks/fetchDetails',
        syncRoom: 'Tasks/syncSection',
        syncTaskResult: 'Tasks/syncTaskResult',
        fetchTaskResult: 'Tasks/fetchTaskResult',
      }),
      ...mapMutations({
        componentAdded: 'Tasks/componentAdded',
        componentModified: 'Tasks/componentModified',
        componentDeleted: 'Tasks/componentDeleted',
        setImages: 'Tasks/setImages',
      }),

      async tap_sync() {
        await this.syncTaskResult(this.task.id)
      },

      tap_add_area () {
        popover('add_area', true)
      },

      async tap_area_template(at) {
        popover('add_area', false)
        const area = {
          icon: at.xlink_href,
          area: at.label,
        };

        if (area.area.indexOf('Other') != -1) {
          const e = await prompt('', '', 'Name', ['CANCEL', 'OK'])
          if (e.index == 1) {
            area.area = e.value.trim()
          }
        }
        area.id = this.room.components.reduce((min, c) => Math.min(min, c.id), 0) -1;
        area.section_id = this.room.id;
        area.images = []
        area.status = ''
        this.room.components.push(area)
        this.componentAdded(this.room.components)
      },

      async tap_delete_area(area, el) {
        if (el) {
          var li = el.currentTarget.parentNode.parentNode
          mui.swipeoutClose(li)
        }
        const e = await confirm('Are you sure to delete this area ?', `Deleting ${area.name}`, ['CANCEL', 'OK'])
        if (e.index == 1) {
          this.componentDeleted(area)
        }
      },

      async tap_rename_area (area, el) {
        if (el) {
          var li = el.currentTarget.parentNode.parentNode
          mui.swipeoutClose(li)
        }

        const e = await prompt('Rename this area', area.area, 'Name', ['CANCEL', 'OK'])
        if (e.index == 1) {
          area.area = e.value.trim()
          this.componentModified(area)
        }
      },

      async tap_item (area) {
        const task_id = this.task.id
        const room_id = area.room_id
        const areas_id = area.id
        this.$router.push(`/checkarea?task_id=${task_id}&room_id=${room_id}&areas_id=${areas_id}`)
        // if (this.task.id) {
        //   const task_id = this.task.id
        //   const category = area.id
        //   const keyResult = `${task_id}-${category}`
        //   const areaText = localStorage.getItem(keyResult)
        //   if (areaText) {
        //     const areaLocal = JSON.parse(areaText)
        //     area.status = areaLocal.status
        //     area.note = areaLocal.note
        //     area.action = areaLocal.action
        //     area.images = areaLocal.images || []
        //     await this.componentModified(area)
        //     localStorage.removeItem(keyResult)
        //   } else {
        //     area.imageURL = this.imageURL(this.task.id, '')
        //     area.note = 'hello world'
        //     const areaText = JSON.stringify(area)
        //     const keyInput = `${task_id}-${category}`
        //     localStorage.setItem(keyInput, areaText)
        //     window.location = `/checkarea.html?task_id=${task_id}&category=${category}`
        //   }
        // }
      },

      async init () {
        let {task_id, house_id, room_id} = this.$router.currentRoute.query
        if (!(task_id || house_id) || !room_id) {
          throw 'invalid ids'
        }
        this.task_id = task_id
        this.house_room_ids = {house_id, room_id}

        if (task_id) {
          this.task = await this.fetchTaskDetails(task_id)
          console.log('this.task', this.task, this.house_room_ids)
          if (this.task) {
            const house_id = this.task.house_id;
            this.house_room_ids.house_id = house_id
          }
          const result = await this.getTaskResult(task_id)
          if (!result) {
            await this.fetchTaskResult(task_id)
          }
        }
        const room = this.rooms.find(r => r.id == room_id)
        if (!room) {
          alert('empty room')
        }
        for (const area of room.components) {
          const category = area.id
          // const keyItem = `${task_id}-${category}`
          // const areaText = await localStorage.getItem(keyItem)
          // if (areaText) {
          //   const areaLocal = JSON.parse(areaText)
          //   console.log('init: areaLocal from localStorage:', keyItem)
          //   // alert(areaText)
          //   // alert(`areaLocal from localStorage:${keyItem}, ${areaLocal.name} ${areaLocal.images.length} images`)
          //   area.status = areaLocal.status
          //   area.note = areaLocal.note
          //   area.action = areaLocal.action
          //   const images = areaLocal.images || []
          //   await this.componentModified(area)
          //   await this.setImages({ task_id, category, images})
          // }
        }

      },
    },
    async mounted() {
      await this.init()
      enableScroll('.mui-scroll-wrapper')
    }
  }

</script>

<style scoped>
  .active {
    color: green;
  }

  .tab-label {
    color: white;
  }
</style>