
<style scoped>
  #search {
    color: navy;
  }

	.title {
		margin: 35px 15px 10px;
	}

	.title+.content {
		margin: 10px 15px 35px;
		color: #bbb;
		text-indent: 1em;
		font-size: 14px;
		line-height: 24px;
	}

	.iconBig {
		width: 3rem;
		height: 3rem;
	}

  #searchdiv {
    top: 2.6rem;
  }

  .mui-search.mui-active input[type=search] {
    text-align: left;
    color: darkblue;
    background-color: burlywood;
  }

  .mui-badge {
    margin: 0px;
    padding: 4px 10px;
  }

  .account {
    color: white;
  }

  .mui-scroll-wrapper {
    top: 5.1rem;
    bottom: 3.7rem;
  }

  .list-icon {
    width: 2rem;
    height: 2rem;
  }
</style>

<template>
  <div id="page">
    <header class="mui-bar mui-bar-nav">
      <h1 id="title" class="mui-title">Properties</h1>
      <a class="mui-icon material-icons mui-pull-right" v-on:tap="tap_house_add()">add</a>
      <div id="searchdiv" class="mui-input-row mui-search">
        <input id="search" type="search" class="mui-input-clear" placeholder="" v-model="params.filter">
      </div>
    </header>
    <div class="mui-content">
      <div class="mui-scroll-wrapper">
        <div class="mui-scroll" >
          <div class="mui-text-center" v-if='houses.length <=0'>
            <svg class="icon" href="#" aria-hidden="true" style="height: 10rem; width: 10rem;">
              <use xlink:href='#icon-wushuju'></use>
            </svg>
            <h3>Loading house list</h3>
          </div>

          <ul class="mui-table-view" v-else>
              <li class="mui-table-view-cell mui-media" v-for="h in filterHouses" :key="h.house_id" v-on:tap="tap_item(h)">
                <div style="display: flex; align-items: baseline;">
                  <span class="mui-badge mui-pull-left" style="font-size: large; color: navy">{{h.house_id}}</span>
                  <span class="mui-badge mui-pull-left" style="margin: 0 .5rem;" v-bind:class="inspection_mui_badge_color(h)" v-if="h.lastInspectionTime">
                    {{lastInspectionTime(h)}}
                  </span>
                  <span class="mui-badge mui-pull-right" style="margin: 0 .5rem;" v-bind:class="inspection_mui_badge_color(h)" v-if="h.note">
                    {{h.note}}
                  </span>
                </div>
                <div class="mui-slider-handle">
                  <svg class="mui-icon list-icon mui-pull-left" aria-hidden="true">
                    <use xlink:href='#icon--fangzi'></use>
                  </svg>

                  <div class="mui-media-body" style="margin-left:2.5rem;">
                    <span class="mui-pull-right mui-badge account"  :style="{ 'background-color': h.icon_color }">{{h.account}}</span>
                    <span class="mui-pull-right mui-badge mui-badge-yellow" v-if="h.issuesCount">{{h.issuesCount}}</span>

                    <span class="mui-pull-left mui-ellipsis" style="color: navy;">
                      <span v-if="h.unit && h.num">{{h.unit}}/{{h.num}}</span>
                      <span v-else-if="h.unit">{{h.unit}}</span>
                      <span v-else-if="h.num">{{h.num}}</span>
                      {{h.street}}
                    </span>
                    <br>
                    <div>
                      <p class="mui-pull-left">{{h.suburb}},{{h.city}}</p>
                    </div>
                  </div>

                </div>
              </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="address_pop" class="mui-popover" style="width: 100%; height: 14rem; left: 0; bottom: 0rem;">
      <div class="mui-popover-arrow"></div>
        <div class="mui-scroll"  style="height: 14rem;">
          <div class="mui-content-padded">
            <span class="mui-badge" style="margin-bottom: .3rem;">Address</span>
            <div class="mui-input-row">
              <input style="width: 49%;" type="text" placeholder="Unit" v-model="address.unit">
              <input style="width: 49%;" type="text" placeholder="Num" v-model="address.num">
            </div>
            <div class="mui-input-row">
              <input style="width: 99%;" type="text" placeholder="Road" v-model="address.street">
            </div>
            <div class="mui-input-row">
              <input style="width: 99%;" type="text" placeholder="Suburb" v-model="address.suburb">
            </div>
            <div class="mui-input-row">
              <input style="width: 49%;" type="text" placeholder="City" v-model="address.city">
              <input style="width: 49%;" type="text" placeholder="Country" v-model="address.country" :readonly="!isEdit">
            </div>
            <button type="button" class="mui-btn mui-btn-red mui-pull-left" v-on:tap="address_pop_cancel()">Cancel</button>
            <button type="button" class="mui-btn mui-btn-green mui-pull-right" v-on:tap="address_pop_ok()">OK</button>
          </div>
        </div>
    </div>

    <BottomBar actived='/houses' />

  </div>
</template>

<script>
  // @ is an alias to /src
  import mui from "@/assets/js/mui.min";
  import moment from "moment";
  import BottomBar from "@/views/bottomBar";
  import {toast, alert, confirm, prompt, enableScroll, popover} from "@/assets/js/api";
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  export default {
    name: "HouseList",
    components: { BottomBar },
    data() {
      return {
        params: {
          filter: '',
          leaseOnly: true,
          includingArchived: false,
        },
        address: {
          unit: '',
          num: '',
          street: '',
          suburb: '',
          city: 'Christchurch',
          country: 'New Zealand'
        },
        isEdit: false,
      };
    },
    computed: {
      ...mapGetters({
        houses: 'HouseList/houseList',
        token: 'Accounts/token',
        account: 'Accounts/account',
        filter: 'Tasks/filter',
      }),
      filterHouses() {
        let filtered = this.houses;
        if (!filtered) {
          console.log("filtered is null")
          return [];
        }
        if (this.params.filter) {
          let filter = this.params.filter
          const reg = new RegExp(filter.toLowerCase().replace(/\s+/g, '\\s+'))
          filtered = filtered.filter((house) => {
            // Create an array with values that we want to search
            const matcher = [
              house.unit + '/' + house.num,
              house.street,
              house.suburb,
              house.city,
              house.country,
            ]
            // Convert the array to string and find the matches
            return reg.test(matcher.join(' ').toLowerCase()) || filter == house.house_id
          });
        } else {
          if (this.params.leaseOnly) {
            filtered = filtered.filter(h => h.leaseCheckoutDate)
          }
          if (!this.params.includingArchived) {
            filtered = filtered.filter(h => !h.archived)
          }
          if (this.filter.onlyMe) {
            const account_id = this.account.id
            filtered = filtered.filter(h => h.pm_id == account_id)
          }
        }
        return filtered
      },
      inspection_mui_badge_color() {
        return (h) => {
          const secondsInWeek = 7 * 24 * 60 * 60;
          if (!h.lastInspectionTime) {
            return ''
          }
          const now = new Date().getTime();
          const weeks = (now/1000 - h.lastInspectionTime) / secondsInWeek;
          if (weeks > 12) {
            return 'mui-badge-red'
          } else if (weeks > 9) {
            return 'mui-badge-green'
          } else {
            return 'mui-badge-blue'
          }
        }
      },
    },
    methods: {
      ...mapActions({
        addHouse: 'HouseList/addHouse',
        listAllHouses: 'HouseList/list',
      }),

      lastInspectionTime(h) {
        return moment(h.lastInspectionTime * 1000).format('DD/MMM/YYYY')
      },

      tap_house_add () {
        popover('address_pop', true)
      },

      async address_pop_ok() {
        const house = await this.addHouse(this.address)
        if (house) {
          this.$router.push(`houses/${house.id}`)
        }
        popover('address_pop', false)
      },

      address_pop_cancel() {
        popover('address_pop', false)
      },

      tap_item(h) {
        console.log({to: `/houses/${h.house_id}`})
        this.$router.push(`/houses/${h.house_id}`)
      },

    },

    async mounted() {
      await this.listAllHouses()
      enableScroll('.mui-scroll-wrapper')
      if (!this.token || !this.account) {
        this.$router.push("/signin")
      }
    },
  }


</script>
