<template>
	<div id="room_page">
		<header class="mui-bar mui-bar-nav">
      <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
			<h1 class="mui-title">{{title}}</h1>
		</header>
		<div class="mui-content">
			<div style="text-align:center">
				<svg style="width: 2rem;height: 2rem;" aria-hidden="true">
					<use v-bind="{'xlink:href':room_data.icon}"></use>
				</svg>
			</div>
			<div class="mui-content-padded">
				<div class="mui-row">
					<span class="mui-badge mui-pull-left">Areas</span>
          <span class="mui-icon material-icons mui-pull-right" style="color:blue;" aria-hidden="true" v-on:tap="tap_add_area()">add_circle_outline</span>
				</div>
				<ul class="mui-table-view">
					<li class="mui-table-view-cell" v-for="area in areas" :key="area.id">
						<div class="mui-slider-right mui-disabled">
							<a class="mui-btn mui-btn-red" v-on:tap.stop='tap_delete_area(area,$event)'>DELETE</a>
						</div>
						<div class="mui-slider-left mui-disabled">
							<a class="mui-btn mui-btn-blue" v-on:tap.stop='tap_rename_area(area,$event)'>RENAME</a>
						</div>
						<div class="mui-slider-handle">
							<svg class="icon mui-pull-left" aria-hidden="true">
								<use v-bind="{'xlink:href':area.icon}"></use>
							</svg>
              <span class="mui-pull-left">{{area.area}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div id="add_area" class="mui-popover" style="width: 100%; height: 27rem; left: 0; bottom: 0rem;">
			<div class="mui-popover-arrow"></div>
			<div class="mui-scroll-wrapper">
				<div class="mui-scroll">
        <div class="mui-content-padded">
					<ul class="mui-table-view">
						<li v-for="at in areaTemplates" :key="at.label" class="mui-table-view-cell" v-on:tap="tap_area_template(at)">

							<svg class="icon mui-pull-left" aria-hidden="true">
								<use :xlink:href="at.xlink_href"></use>
							</svg>
							<a>
								{{at.label}}
							</a>
						</li>
					</ul>
				</div>
        </div>
			</div>
		</div>
	</div>
</template>

<script>

  import mui from "@/assets/js/mui.min";
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import {enableScroll, prompt, popover} from "@/assets/js/api";

  export default {
    name: "Room",
    data() {
      return {
        house_room_ids: {},
        room_data: {},
        areaTemplates: [
          { xlink_href: '#icon-yugang-', label: 'Bath' },
          { xlink_href: '#icon-chuanglian', label: 'Blinds/Curtains' },
          { xlink_href: '#icon-pudiban', label: 'Floors/Fi.coverings' },
          { xlink_href: '#icon-taideng', label: 'Lights/Power points' },
          { xlink_href: '#icon-shuzhuangtai', label: 'Mirror/cabinet' },
          { xlink_href: '#icon-xizao', label: 'Shower' },
          { xlink_href: '#icon-matong', label: 'Toilet(WC)' },
          { xlink_href: '#icon-zhuanqiang', label: 'Walls/Doors' },
          { xlink_href: '#icon-xishoutai', label: 'Wash Basin' },
          { xlink_href: '#icon-chuangkou', label: 'Windows' },
          { xlink_href: '#icon-dengzi', label: 'Bench' },
          { xlink_href: '#icon-reshuiqi', label: 'Stove' },
          { xlink_href: '#icon-kaoxiang', label: 'Oven' },
          { xlink_href: '#icon-youyanji', label: 'Fans/Extractors' },
          { xlink_href: '#icon--yuechi', label: 'Key' },
          { xlink_href: '#icon-qita', label: 'Furniture' },
          { xlink_href: '#icon-qita', label: 'Area' },
          { xlink_href: '#icon-Other', label: 'Other' },
        ]
      }
    },
    computed: {
      ...mapGetters({
        getRoom: 'HouseList/getRoom',
      }),
      areas() {
        return this.room_data? this.room_data.areas: [];
      },
      title() {
        return this.room_data? this.room_data.name : 'Room'
      },
    },
    methods: {
      ...mapActions({
        addArea: 'HouseList/addArea',
        renameArea: 'HouseList/renameArea',
        deleteArea: 'HouseList/deleteArea',
      }),

      async tap_delete_area(area, el) {
        if (el) {
          var li = el.currentTarget.parentNode.parentNode
          mui.swipeoutClose(li)
        }
        await this.deleteArea({...this.house_room_ids, areas_id: area.id})
      },

      async tap_rename_area (area, el) {
        if (el) {
          var li = el.currentTarget.parentNode.parentNode
          mui.swipeoutClose(li)
        }

        const e = await prompt('Rename this area', area.area, 'Name', ['CANCEL', 'OK'])
        if (e.index == 1) {
          var value = e.value
          const name = value.trim()
          await this.renameArea({...this.house_room_ids, areas_id: area.id, name})
        }
      },

      tap_add_area () {
        popover('add_area', true)
      },

      async tap_area_template(at) {
        popover('add_area', false)
        const area = {
          icon: at.xlink_href,
          area: at.label,
        };

        if (area.area.indexOf('Other') != -1) {
          const e = await prompt('', '', 'Name', ['CANCEL', 'OK'])
          if (e.index == 1) {
            area.area = e.value.trim()
          }
        }
        await this.addArea({...this.house_room_ids, area})
      },

      async init() {
        const {house_id, room_id} = this.$route.params;
        this.room_data = this.getRoom({house_id, room_id}) || {}
        this.house_room_ids = {house_id, room_id}
      },
    },

    async mounted() {
      await this.init()
      enableScroll('.mui-scroll-wrapper')
    }
  }

</script>

