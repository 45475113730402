<template>
  <div class="mui-table-view-cell" style="margin: .5rem;">
    <div class="mui-pull-left">
      <span v-if="house.unit && house.num">{{house.unit}}/{{house.num}}</span>
      <span v-else-if="house.unit">{{house.unit}}</span>
      <span v-else-if="house.num">{{house.num}}</span>
      <span>{{house.street}},{{house.suburb}}</span>
      <br><span>{{house.city}}, {{house.country}}</span>
    </div>
    <div class="mui-pull-right">
      <span class="mui-badge mui-pull-right" style="padding:10px; font-size: large; color: navy;" v-on:tap="tap_map()">{{house.id}}</span>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";

  export default {
    name: "MapLocator",
    props: {
      house: {
        type: Object,
        required: true,
      }
    },
    methods: {
      tap_map() {
        const ref = `https://www.google.co.nz/maps/place/${this.house.num} ${this.house.street}, ${this.house.suburb}`
        window.location.href = ref
      },
    },
  }
</script>

<style scoped>
</style>