<style scoped>
	.icon {
		width: 1.2rem;
		height: 1.2rem;
	}

	button {
		width: 8rem;
	}

  .status_btn {
    width: 120px;
    height: 40px;
  }

  .alliswell {
    width: 240px;
    height: 40px;
  }

	.status_btn_div {
		margin-top: .5rem;
	}

  .tab-label {
    color: white;
  }

  .mui-scroll-wrapper {
    bottom: 3.7rem;
  }
</style>

<template>
		<div id="area_page" v-if="task && area">
			<header class="mui-bar mui-bar-nav">
				<a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left" :disabled="busy"></a>
				<h1 class="mui-title">{{area.area || area.name}}</h1>
        <span class="mui-btn mui-btn-yellow mui-btn-link mui-btn-nav mui-pull-right" id="action" v-on:tap="tap_action()">Action</span>
			</header>
			<div class="mui-content">
        <div class="mui-scroll-wrapper">
					<div class="mui-scroll">
            <div style="text-align:right; color:blue; padding: 1rem 0;">
              <span>{{actionLabel}}</span>
            </div>
            <div style="text-align:center; margin-top: .5rem;" class="command_btn_div">
							<button type="button" class="alliswell mui-btn" :class="{ 'mui-btn-green': area.status && area.status.includes('CLEAN')}" v-on:tap='tap_alliswell()'>ALL IS WELL</button>
						</div>
						<br />
						<div style="text-align:center" class="status_btn_div">
							<button type="button" class="status_btn mui-btn" :class="{ 'mui-btn-danger': area.status && area.status.includes('MESSY')}">MESSY</button>
							<button type="button" class="status_btn mui-btn" :class="{ 'mui-btn-danger': area.status && area.status.includes('DIRTY')}">DIRTY</button>
							<button type="button" class="status_btn mui-btn" :class="{ 'mui-btn-danger': area.status && area.status.includes('MOLDY')}">MOLDY</button>
						</div>
						<div style="text-align:center" class="status_btn_div">
							<button type="button" class="status_btn mui-btn" :class="{ 'mui-btn-danger': area.status && area.status.includes('MARKS')}">MARKS</button>
							<button type="button" class="status_btn mui-btn" :class="{ 'mui-btn-danger': area.status && area.status.includes('HOLES')}">HOLES</button>
							<button type="button" class="status_btn mui-btn" :class="{ 'mui-btn-danger': area.status && area.status.includes('NOT WORKING')}">NOT WORKING</button>
						</div>
						<div style="text-align:center" class="status_btn_div">
							<button type="button" class="status_btn mui-btn" :class="{ 'mui-btn-danger': area.status && area.status.includes('LEAKING')}">LEAKING</button>
							<button type="button" class="status_btn mui-btn" :class="{ 'mui-btn-danger': area.status && area.status.includes('DAMAGED')}">DAMAGED</button>
							<button type="button" class="status_btn mui-btn" :class="{ 'mui-btn-danger': area.status && area.status.includes('MISSING')}">MISSING</button>
						</div>

            <div class="mui-content-padded" style="padding: .5em;">
              <textarea v-model="area.note" placeholder="Note"
                style="height: 5rem; margin: 0; font-size: .9rem; padding: 0.1rem; overflow:auto;">
              </textarea>
              <Gallary :task="task" :category="area.id" @modified="imagesModified($event)" file camera v-if="task && area.id"/>
            </div>

          </div>
				</div>
			</div>

      <div id="action_pop" class="mui-popover" style="top: 0; right: 0; height: 20rem;">
        <ul class="mui-table-view">
            <li class="mui-table-view-cell" :class="{selected: area && option.value === area.action }"
              v-for="option in actionOptions" :key="option.label"
              :id="option.value" v-on:tap="setAction(option.value)">
                {{option.label}}
            </li>
        </ul>
      </div>

      <nav class="mui-bar mui-bar-tab wrapper">
        <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_sync()' :disabled="busy">
          <span class="tab-label">UPLOAD PHOTOS</span>
        </button>
      </nav>

    </div>
</template>

<script>
  import Vue from "vue";
  import mui from "@/assets/js/mui.min";
  import {mapGetters, mapActions, mapMutations } from 'vuex'
  import {toast, enableScroll, popover } from "@/assets/js/api";
  import Gallary from "@/components/Gallary"

  export default {
    name: "CheckArea",
    components: {
      Gallary,
    },
    data() {
      return {
        areas_id: '',
        room_id: '',
        issue: {},

        task: null,

        actionOptions: [{
          label: 'No extra action needed for maintenance',
          value: ''
        }, {
          label: 'Tenant should take action later, before moving out',
          value: 'tenant_action_on_moveout'
        }, {
          label: 'Tenant should take action immediately',
          value: 'tenant_action_immediately'
        }, {
          label: 'Owner is asked to resolve the issue',
          value: 'owner_action_immediately'
        }, {
          label: 'Both tenant and owner should take actions to resolve the issue',
          value: 'both_action_immediately'
        }],
      }
    },
    computed: {
      ...mapGetters({
        busy: 'Tasks/busy',
        getResult: 'Tasks/getTaskResult',
      }),
      sections() {
        return this.getResult(this.task.id) || []
      },
      room() {
        if (!this.room_id) {
          return null
        }
        return this.sections.find(s => s.id == this.room_id)
      },
      area() {
        if (!this.room) {
          return null
        }
        return this.room.components.find(s => s.id == this.areas_id)
      },

      actionLabel() {
        if (!this.area.action) {
          return 'No action specified'
        }
        const found = this.actionOptions.find(op => op.value === this.area.action);
        if (!found) {
          return 'No action specified'
        }
        return found.label;
      },
    },
    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        fetchTaskDetails: 'Tasks/fetchDetails',
        fetchTaskResult: 'Tasks/fetchTaskResult',
        setIssue: 'HouseList/setIssue',
        syncArea: 'Tasks/syncComponent',
        syncTaskResult: 'Tasks/syncTaskResult',
      }),
      ...mapMutations({
        componentModified: 'Tasks/componentModified',
      }),

      // async tap_sync() {
      //   this.busy = true;
      //   this.area.section_id = this.room_id;
      //   await this.syncArea({
      //     task_id: this.task.id,
      //     category: this.task.title,
      //     component: this.area})
      //   this.busy = false;
      // },
      async tap_sync() {
        // console.log('tap_sync', this.task.id)
        await this.syncTaskResult(this.task.id)
      },


      hasStatus(status) {
        if (!this.area.status) {
          return false;
        }
        const enums = this.area.status.split(',');
        return enums.includes(status);
      },

      async tap_status (status) {
        if (!this.area.status) {
          this.area.status = status;
          return;
        }

        const enums = this.area.status.split(',');
        if (enums.includes(status)) {
          enums.splice(enums.indexOf(status), 1)
        } else {
          enums.push(status);
        }
        if (enums.includes('CLEAN') && enums.length > 1) {
          enums.splice(enums.indexOf('CLEAN'), 1)
        }

        this.area.status = enums.join(',')
        this.componentModified(this.area)
      },

      async tap_alliswell() {
        this.area.status = 'CLEAN'
        this.componentModified(this.area)
      },

      async tap_action () {
        popover('action_pop', true)
      },

      async setAction(action) {
        popover('action_pop', false)

        this.area.action = action
        this.componentModified(this.area)

        this.setIssue({
          house_id: this.task.house_id,
          section: this.room.name,
          component: this.area.name || this.area.area,
          position_locator: `${this.room.id}/${this.area.id}`,
          description: this.area.note,
          actions: [{
            task_id: this.task.id,
            status: this.area.status,
            effect: this.area.note,
            assignee: this.getAssignee(action),
            action: this.getWhat2Do(action),
          }]
        })
      },

      getAssignee(action) {
        if (!action) {
          return ''
        }
        switch(action) {
          case 'tenant_action_on_moveout': return 'tenant';
          case 'tenant_action_immediately': return 'tenant';
          case 'owner_action_immediately': return 'owner';
          case 'both_action_immediately': return 'owner,tenant';
        }
      },

      getWhat2Do(action) {
        if (!action) {
          return ''
        }
        switch(action) {
          case 'tenant_action_on_moveout': return 'Resolve it before moving out';
          case 'tenant_action_immediately': return 'Resolve it immediately';
          case 'owner_action_immediately': return 'Resolve it immediately';
          case 'both_action_immediately': return 'Resolve it immediately';
        }
      },

      imagesModified(e) {
        this.componentModified(this.area)
      },

      async init() {
        const {task_id, room_id, areas_id} = this.$router.currentRoute.query
        if (!task_id || !room_id || !areas_id) {
          throw 'invalid ids'
        }
        this.task = await this.fetchTaskDetails(task_id)
        if (!this.task) {
          throw 'invalid task_id'
        }
        await this.fetchTaskResult(task_id)
        this.house = await this.fetchHouseDetails(this.task.house_id)
        if (!this.house) {
          throw 'invalid task.house_id'
        }
        this.room_id = room_id
        this.areas_id = areas_id
        console.log({area: this.area})
      }
    },
    async mounted() {
      await this.init()
      mui('.mui-content').on('tap', '.status_btn_div', async (ev) => {
        const btn = ev.target
        await this.tap_status(btn.innerText)
      })
      enableScroll('.mui-scroll-wrapper')
    }
  }
</script>
