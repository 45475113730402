<style scoped>
  .select-staff {
    width: 50%;
    font-weight: bold;
    color: navy;
    margin-bottom: auto;
    padding: 0 22px;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 9px;
  }

  .function {
    height: 3.5rem;
    margin: 4px;
    flex: 1;
    color: white;
  }
  .choice {
    height: 2.5rem;
    margin: 0 15px;
    flex: 1;
  }

  .keyStatus {
    margin: 5px;
    padding: 10px;
  }

  .mui-scroll-wrapper {
    bottom: 4rem;
  }

</style>

<template>
  <div id="page" v-if="task">
    <header class="mui-bar mui-bar-nav">
      <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
      <h1 class="mui-title">{{task_title}}</h1>
    </header>
    <div class="mui-content">
      <div class="mui-scroll-wrapper">
        <div class="mui-scroll">
          <HouseGlance :house="house" />

          <ul class="mui-table-view" style="margin: 0.5rem;">
              <li class="mui-table-view-cell" style="margin-top: 0;" v-on:tap="tap_setdue()">
                <span class="mui-pull-left">{{task_status}}</span>
                <span class="mui-pull-right" style="color: brown; padding: 0 20px;">due to {{task_due_date}}</span>
              </li>
          </ul>

          <ul class="mui-table-view" style="margin: 0.5rem;">
            <li class="mui-table-view-cell" style="margin-top: 0.25rem;">
              <label class="mui-pull-left middle" for="staff">Assign to:</label>
              <select class="mui-pull-right select-staff" dir="rtl" name="staff" id="id" :value="task_assignee.id"  @change="setAssignee($event.target.value)">
                <option v-for="p in performers" :value="p.id" :key="p.id" :selected="p.id === task_assignee.id">{{p.account}}</option>
              </select>
            </li>
          </ul>

          <ul class="mui-table-view" style="margin: 0.5rem;">
            <li class="mui-table-view-cell">
              <div class="mui-row">
                <textarea v-model="task.description" placeholder="Task description"
                  style="height: 8rem; margin: 0; font-size: .9rem; padding: 0.5rem; margin: -0.5rem; overflow:auto;">
                </textarea>
              </div>
            </li>
            <!--
            <li class="mui-table-view-cell">
              <Gallary :task="task" category="description" file camera />
            </li>
            -->
          </ul>

          <ul class="mui-table-view" style="margin: 0.5rem;">
            <li class="mui-table-view-cell">
              <div class="mui-row" style="margin: 0;">
                <span class="mui-pull-left mui-badge keyStatus">
                  {{keyStatusText}}
                </span>
              </div>
              <div class="mui-row" style="margin: .25rem;" v-if="task.status > 0">
                <div class="wrapper">
                  <button type="button" class="tab-item mui-btn mui-btn-green choice" v-on:tap='tap_borrow_key()' :disabled="!allowBorrow">
                    <span class="tab-label">BORROW</span>
                  </button>
                  <button type="button" class="tab-item mui-btn mui-btn-primary choice" v-on:tap='tap_return_key()' :disabled="!isBorrowed">
                    <span class="tab-label">RETURN</span>
                  </button>
                </div>
              </div>
            </li>
          </ul>

          <ul class="mui-table-view" style="margin: 0.5rem;">
            <li class="mui-table-view-cell mui-collapse">
              <a class="mui-row mui-navigate-right" href="#">
                <span class="mui-pull-left" style="font-weight: 700; color:darkblue">Operation history</span>
              </a>
              <div class="mui-collapse-content mui-table-view" style="margin-top: .5rem;" v-for="note in notes" :key="note.id">
                <div class="mui-table-view-cell">
                  <div class="mui-row">
                    <span class="mui-badge mui-badge-grey mui-pull-left">{{note.account? note.account.account: ''}} </span>
                    <span class="mui-badge mui-badge-grey mui-pull-left">{{note.created_at}}</span>
                  </div>
                  <div class="mui-row">
                    <span class="mui-pull-left" style="color:blue">{{note.message}}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <selectDueDate ref="selectDueDate"/>

    <nav class="mui-bar mui-bar-tab">
      <div class="wrapper" v-if="task.status == 0">
        <button type="button" class="tab-item mui-btn mui-btn-yellow function" v-on:tap='tap_reschedule()'>
          <span class="tab-label">SCHEDULE</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_confirm()'>
          <span class="tab-label">CONFIRM</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-purple function" v-on:tap='tap_cancel()'>
          <span class="tab-label">CANCEL</span>
        </button>
      </div>

      <div class="wrapper" v-if="task.status == 1">
        <button type="button" class="tab-item mui-btn mui-btn-yellow function" v-on:tap='tap_reschedule()'>
          <span class="tab-label">SCHEDULE</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_start_performing()' :disabled="busy">
          <span class="tab-label">PERFORM</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-blue function" disabled>
          <span class="tab-label">FINISH</span>
        </button>
      </div>

      <div class="wrapper" v-if="task.status == 2">
        <button type="button" class="tab-item mui-btn mui-btn-yellow function" v-on:tap='tap_reschedule()' :disabled="busy">
          <span class="tab-label">SCHEDULE</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_start_performing()' :disabled="busy">
          <span class="tab-label">PERFORM</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-blue function" v-on:tap='tap_finish()' :disabled="busy">
          <span class="tab-label">FINISH</span>
        </button>
      </div>

      <div class="wrapper" v-if="task.status == 7">
        <button type="button" class="tab-item mui-btn mui-btn-yellow function" disabled>
          <span class="tab-label">SUMMARY</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-red function" disabled>
          <span class="tab-label">REVISION</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-primary function" v-on:tap='show_sendMail()' :disabled="busy">
          <span class="tab-label">REPORT</span>
        </button>
      </div>

      <div class="wrapper" v-if="task.status == 8 || task.status == 9 || task.status == 10">
        <button type="button" class="tab-item mui-btn mui-btn-yellow function" v-on:tap='tap_view_summary()' :disabled="busy">
          <span class="tab-label">SUMMARY</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_start_revision(task)'>
          <span class="tab-label">REVISION</span>
        </button>
        <button type="button" class="tab-item mui-btn mui-btn-primary function" v-on:tap='show_sendMail()' :disabled="busy">
          <span class="tab-label">REPORT</span>
        </button>
      </div>
    </nav>

  </div>
</template>

<script>
  import Vue from 'vue';
  import moment from "moment";
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import {toast, alert, confirm, prompt, enableScroll, popover, sleep } from "@/assets/js/api";
  import {KeyStatus} from "@/helpers/keyStatus";
  import selectDueDate from "@/components/selectDueDate"
  import HouseGlance from "@/components/HouseGlance"
  import Gallary from "@/components/Gallary"

  export default {
    name: "Taskboard",
    components: {
      selectDueDate,
      HouseGlance,
    },
    data() {
      return {
        task: null,
        house: {},
        busy: false,
        backurl: '',
        performer: {},
        today: moment().startOf('day').add(12, 'h'),
        customDate: moment().format('YYYY-MM-DD'),
      }
    },
    computed: {
      ...mapGetters({
        getHouse: 'HouseList/getHouse',
        getTask: 'Tasks/getTask',
        performers: 'Tasks/allPerformers',
      }),
      notes() {
        return this.task.note
      },
      task_due_date() {
        return moment.unix(this.task.timestamp).format('DD/MMM/YYYY')
      },
      performerSelects() {
        return this.performers
      },
      keyStatus() {
        if (!this.task) return KeyStatus.NO_KEY_INFO
        if (!this.task.house) return KeyStatus.NOT_AVAILABLE
        if (!this.task.house.keyNumber) return KeyStatus.NOT_BACKUP
        if (!this.task.key) return KeyStatus.DIDNOT_BORROW
        if (this.task.key.state === 0) return KeyStatus.IS_BORROWED
        return KeyStatus.IS_RETURNED
      },
      keyStatusText() {
        switch(this.keyStatus) {
          case KeyStatus.NOT_AVAILABLE: return 'NOT AVAILABLE'
          case KeyStatus.NOT_BACKUP: return 'NO BACKUP KEY FOR THIS HOUSE'
          case KeyStatus.DIDNOT_BORROW: return 'BACKUP KEY AVAILABLE TO BORROW'
          case KeyStatus.IS_BORROWED: return 'KEY IS BORROWED, PLEASE REMEMBER TO RETURN'
          case KeyStatus.IS_RETURNED: return 'KEY IS RETURNED, THANK YOU'
          default: case KeyStatus.NO_KEY_INFO: return 'NO KEY INFO'
        }
      },
      allowBorrow() {
        return this.keyStatus === KeyStatus.DIDNOT_BORROW || this.keyStatus === KeyStatus.IS_RETURNED
      },
      isBorrowed() {
        return this.keyStatus === KeyStatus.IS_BORROWED
      },
      task_title() {
        switch (this.task.title) {
          case 'inspections':
            return 'Regular inspection'
          case 'hhs':
            return 'Health House Statement'
          case 'maintenance':
            return 'Maintenance'
        }
        return ''
      },
      task_status() {
        switch (this.task.status) {
          case 0: return 'Scheduled'
          case 1: return 'Confirmed'
          case 2: return 'Started'
          case 7: return 'Generating...'
          case 8: return 'Finished'
          case 9: return 'Reported'
          case 10: return 'Archived'
          default: return 'Pending'
        }
      },
      task_todo() {
        switch (this.task.status) {
          case 0: return 'Ready to conform'
          case 1: return 'Ready to perform'
          case 2: return 'Ready to report'
          case 7: return 'Report is preparing'
          case 8: case 9: case 10:
            return 'Review the inspection'
          default: return 'Unknown'
        }
      },
      task_assignee() {
        return this.task.assignee || { account: 'anyone'};
      }
    },

    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        fetchTaskDetails: 'Tasks/fetchDetails',
        updateAssignee: 'Tasks/updateAssignee',
        uploadTask: 'Tasks/uploadTask',
        uploadTaskSettings: 'Tasks/uploadTaskSettings',
        schedule: 'Tasks/schedule',
        update: 'Tasks/uploadDetails',
        confirm: 'Tasks/confirm',
        cancel: 'Tasks/cancel',
        startPerforming: 'Tasks/startPerforming',
        revision: 'Tasks/revision',
        postKey: 'HouseList/postKey',
        updateHouse: 'HouseList/updateHouse',
      }),
      ...mapMutations({
        setTaskDetails: 'Tasks/setDetails',
      }),

      async tap_view_summary() {
        this.$router.push(`/finish?task_id=${this.task.id}`)
      },

      async tap_finish() {
        this.$router.push(`/finish?task_id=${this.task.id}`)
      },

      async show_sendMail() {
        this.$router.push(`/viewsend?task_id=${this.task.id}`);
      },

      async tap_reschedule() {
        const timestamp = await this.$refs.selectDueDate.show('Reschedule the task');
        if (!timestamp) {
          return
        }
        this.task.timestamp = timestamp
        if (this.task.title === 'inspections') {
          if (!this.task.subtitle) {
            const e = await confirm('', `Is it a moving in/out report ?`, ['NO', 'YES'])
            if (e.index == 0) {
              this.task.subtitle = 'normal';
            } else {
              this.task.subtitle = 'moving_inout';
            }
            this.schedule(this.task)
          }
        }
      },

      async tap_setdue() {
        const timestamp = await this.$refs.selectDueDate.show('Reset Due date');
        if (!timestamp) {
          return
        }
        if (this.task.status >= 2) {
          return;
        }
        await sleep(100)

        this.task.timestamp = timestamp
        this.schedule(this.task)
      },

      async tap_confirm() {
        const e = await confirm('Are you sure to confirm this task?', 'Confirm', ['NO', 'YES'])
        if (e.index == 1) {
          await this.confirm(this.task)
        }
      },

      async setAssignee(assignee_id) {
        if (this.task.status >= 7) {
          return;
        }
        const assignee = this.performers.find(p => p.id == assignee_id)
        if (!assignee) {
          return;
        }

        this.task.assignee = assignee
        this.task.assignee_id = assignee.id
      },

      async tap_cancel() {
        const e = await confirm('Are you sure to cancel this task AT ALL?', 'Cancel', ['NO', 'YES'])
        if (e.index == 1) {
          await this.cancel(this.task)
          this.$router.go(-1)
        }
      },

      async tap_start_performing() {
        const task = this.task
        await this.startPerforming(task);
        if (task.title === 'inspections') {
          this.$router.push(`/inspection?task_id=${task.id}`)
        } else if (task.title === 'maintenance') {
          toast('UNDER CONSTRUCTION')
          // this.$router.push(`/perform?task_id=${task.id}`)
        } else if (task.title === 'hhs') {
          this.$router.push(`/hhsreport?task_id=${task.id}`)
        }
      },

      async tap_start_revision() {
        const e = await confirm('Are you sure to revision this inspection?', 'Confirm', ['CANCEL', 'OK'])
        console.log('tap_start_revision', e)
        if (e.index != 1) {
          return false;
        }
        this.revision(this.task)
      },

      async tap_borrow_key() {
        if (!this.allowBorrow) {
          return;
        }

        const borrower = this.task.assignee? this.task.assignee.account: ''

        const key = await this.postKey({
          house_id: this.task.house_id,
          key_note: [{
            content: `${borrower} borrowed for ${this.task.title}`,
          }],
          state: 0,
        })
        console.log('tap_borrow_key', key)
        await this.setTaskDetails({
          id: this.task.id,
          key_id: key.id,
          key,
        })
        Vue.set(this.task, 'key', key)
      },

      async tap_return_key() {
        if (!this.isBorrowed) {
          return;
        }
        const borrower = this.task.assignee? this.task.assignee.account: ''
        const key = await this.postKey({
            id: this.task.key_id,
            state: 1,
            key_note: [{
              content: `Returned`,
            }]
          })
          this.setTaskDetails({
            id: this.task.id,
            key
          })
          Vue.set(this.task, 'key', key)
      },

      async init() {
        const {task_id} = this.$router.currentRoute.query
        if (!task_id) {
          throw 'invalid task id'
        }
        this.task = await this.fetchTaskDetails(task_id)
        console.log('fetchTaskDetails get', this.task)

        if (this.task) {
          const house_id = this.task.house_id;
          this.house = await this.fetchHouseDetails(house_id)
        }
      },

    },

    async mounted() {
      await this.init()
      enableScroll('.mui-scroll-wrapper')
    },

    async unmounted() {
      console.log('unmounted')
    },

    async beforeRouteLeave(to, from, next) {
      const house = this.house
      await this.updateHouse({
        id: house.id,
        note: house.note
      })
      await this.uploadTaskSettings(this.task.id)
      next()
    }
  }
</script>
