<style scoped>
  .div_btn {
    margin-top: .5rem;
  }

  .statement {
    text-align: left
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>

<template>
  <div id="item_page" v-if="task && component">
    <header class="mui-bar mui-bar-nav">
      <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
      <h1 class="mui-title">{{section.title}}</h1>
    </header>
    <div class="mui-content">
      <div class="mui-scroll-wrapper">
        <div class="mui-scroll">
          <div class="mui-content-padded">
            <span class="mui-inline mui-badge mui-badge-grey mui-pull-left">{{component.title}}</span>
          </div>
          <div class="mui-content-padded mui-pull-left">
            <span class="mui-inline statement">{{component.statement}}</span>
          </div>

          <div v-for="requiry in component.requiries" :key="requiry.question">
            <div class="mui-content-padded">
              <span class="mui-inline mui-badge mui-badge-grey">{{requiry.question}}</span>
            </div>
            <div style="text-align:center; padding-bottom: 20px;" class="div_btn" v-if="requiry.candicates">
              <button type="button" class="mui-btn" style="margin: 10px 8px;" v-for="(c, i) in requiry.candicates"
                v-bind:key="i"
                v-bind:class="requiry.answer == c? i == 0 ?'mui-btn-green': 'mui-btn-warning': 'mui-btn-grey'"
                v-bind:style="{ width: c.length > 8 ? '200px' : '150px' }"
                v-on:tap="setAnswer(requiry, c)">{{c}}</button>
            </div>
            <div style="text-align:center; padding-bottom: 20px;" class="div_btn" v-else>
              <textarea style="border: 0px; height: 2rem; margin: 0 20px;" class="mui-input-clear question" placeholder="Answer" v-model="requiry.answer"></textarea>
            </div>
          </div>

          <div class="mui-content-padded" style="padding: .5em;">
            <textarea v-model="component.note" placeholder="Note"
              style="height: 8rem; margin: 0; font-size: .9rem; padding: 0.1rem; overflow:auto;">
            </textarea>
            <Gallary :task="task" :category="component.id" @modified="imagesModified($event)" file camera  v-if="task && component.id"/>
          </div>


          <br />
          <div class="mui-content-padded" style="padding: .5em;">
            <span class="mui-badge mui-pull-left">Report issues?</span>
              <label class="switch">
                <input type="checkbox" v-model="component.reportIssues">
                <span class="slider round"></span>
              </label>
          </div>
          <br />

        </div>
      </div>

    </div>
  </div>
</template>

<script>

  /*
    <div style="text-align:center; padding: 1rem; margin-top: 2rem;">
      <div class="imgDiv" v-for="(imgObj,index) in component.images" v-bind:key="imgObj.id">
        <img style="width: 5rem; height: 5rem;" :src="imgSrc(imgObj)" />
        <span class="mui-badge delete" v-on:tap="tap_img_delete(index)">X</span>
      </div>
      <div class="imgDiv" v-for="(imgObj,index) in uploadImages" v-bind:key="index">
        <img style="width: 5rem; height: 5rem;" :src="imgSrc(imgObj)" />
        <span class="mui-badge delete" v-on:tap="tap_delete_upload(index)">X</span>
      </div>
    </div>
    <br />
    <input v-show="false" ref="areaImage" type="file" accept="image/*" multiple @change="finish_selectImages($event)" capture="camera">
    <input v-show="false" ref="areaGallery" type="file" accept="image/*" multiple @change="finish_selectImages($event)">
    <div style="text-align:center">
      <svg style="width:2.5rem; height: 2.5rem; margin-top: 2rem; margin-left: 2rem;" aria-hidden="true" v-on:tap="tap_camera()">
        <use xlink:href="#icon--xiangji"></use>
      </svg>
      <svg style="width:2.5rem; height: 2.5rem; margin-top: 2rem; margin-left: 2rem;" aria-hidden="true" v-on:tap="tap_gallery()">
        <use xlink:href="#icon--zhaopian"></use>
      </svg>
    </div>
    <br />
    <div class="mui-content-padded">
      <span class="mui-inline mui-badge mui-badge-grey" style="color: black;">Note</span>
      <div class="mui-input-row">
        <textarea style="border: 0px; height: 10rem;" id='note' class="mui-input-clear question" placeholder="Comments" v-model="component.note"></textarea>
      </div>
    </div>


  */

  import {mapGetters, mapActions, mapMutations } from 'vuex'
  import {toast, alert, confirm, prompt, enableScroll } from "@/assets/js/api";
  import Gallary from "@/components/Gallary"

  export default {
    name: "HHSComponent",
    components: {
      Gallary,
    },
    data() {
      return {
        task: null,
        house: null,
        task_id: 0,
        section_id: '',
        component_id: '',
      }
    },
    computed: {
      ...mapGetters({
        getTaskResult: 'Tasks/getTaskResult',
      }),
      sections() {
        return this.getTaskResult(this.task_id) || []
      },
      section() {
        if (!this.section_id) {
          return null
        }
        return this.sections.find(s => s.id == this.section_id)
      },
      component() {
        if (!this.component_id || !this.section) {
          return null
        }
        if (!this.section.components || !this.section.components.length) {
          return null;
        }
        const component = this.section.components.find(c => c.id == this.component_id)
        return component
      }
    },
    watch: {
      component: {
        handler: (after, before) => {
          if (!this || !after) {
            return;
          }
          this.notifyComponentChanged(after)
          // if (this.task && this.section && before && after.cmd) {
          //   this.notifyComponentChanged(this.component)
          // }
        },
        deep: true
      }
    },
    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        fetchTaskDetails: 'Tasks/fetchDetails',
        fetchTaskResult: 'Tasks/fetchTaskResult',
        syncComponent: 'Tasks/syncComponent',
      }),
      ...mapMutations({
        notifyComponentChanged: 'Tasks/componentModified',
      }),

      async tap_sync() {
        await this.syncComponent({task_id: this.task.id, component: this.component})
      },

      imagesModified(e) {
        // this.notifyComponentChanged(this.component)
      },

      async setAnswer (requiry, c) {
        if (requiry.answer !== c) {
          requiry.answer = c
        } else {
          requiry.answer = ''
        }
      },

      async init () {
        const {task_id, path} = this.$router.currentRoute.query
        if (!task_id) {
          throw 'invalid task id'
        }
        this.task_id = task_id;
        this.task = await this.fetchTaskDetails(task_id)
        if (!this.task) {
          throw 'invalid task_id'
        }
        this.house = await this.fetchHouseDetails(this.task.house_id)
        if (!this.house) {
          throw 'invalid task.house_id'
        }
        const result = await this.getTaskResult(task_id)
        if (!result) {
          await this.fetchTaskResult(task_id)
        }

        const [section_id, component_id] = path.split(':')
        this.section_id = section_id
        this.component_id = component_id

        try {
          if (!this.section) {
            throw { message: 'Failed to locate section in HHS report' }
          }
          if (!this.component) {
            throw { message: 'Failed to locate component in HHS report' }
          }
          console.log(this.component.images)
        } catch (error) {
          alert(error.message)
        }
      },

    },
    async mounted() {
      await this.init()
      enableScroll('.mui-scroll-wrapper')
    }
  }

</script>

