<style lang="scss">
  .mui-bar-tab {
    .tab-item {
      display: table-cell;
      overflow: hidden;
      width: 1%;
      height: 50px;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #929292;
      .mui-icon {
        top: 3px;
        width: 24px;
        height: 24px;
        padding-top: 0;
        padding-bottom: 0;
        ~.tab-label {
          font-size: 12px;
          font-weight: bold;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    padding-bottom: 10px;
  }

  .active {
    font-weight: bolder;
    color: red;
  }

</style>

<template>
  <nav class="mui-bar mui-bar-tab">
    <router-link class="tab-item" to="/todolist">
			<svg class="mui-icon" aria-hidden="true">
				<use xlink:href="#icon--wenjian"></use>
			</svg>
      <span class="tab-label" :class="{ active: actived === '/todolist'}">Tasks</span>
    </router-link>
    <router-link class="tab-item" to="/houses">
			<svg class="mui-icon">
				<use xlink:href="#icon--fangzi"></use>
			</svg>
      <span class="tab-label" :class="{ active: actived === '/houses'}">Properties</span>
    </router-link>
    <router-link class="tab-item" to="/settings">
			<svg class="mui-icon" aria-hidden="true">
				<use xlink:href="#icon--shezhi"></use>
			</svg>
      <span class="tab-label" :class="{ active: actived === '/settings'}">Settings</span>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "BottomBar",
  props: {
    actived: {
      type: String,
      required: true,
    },
  },
};
</script>

