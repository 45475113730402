import Vue from 'vue'
import moment from 'moment'
import axios from 'axios'
import { toast, confirm } from '@/assets/js/api'
import { TaskStatus } from '../helpers/taskStatus'

const state = {
  filter: {
    showfinished: true,
    onlyMe: false,
    onlyMyWorklist: false,
    onlyMyCreatedWorklist: false,
    // hotlinkToServer: false,
  },
  todolist: [],
  detailedTasks: {},
  taskResults: {},
  allPerformers: [],
  busy: false,
  getTask(task_id) {
    return this.detailedTasks[task_id]
  },
  getResult(task_id) {
    return this.taskResults[task_id]
  },
  taskUploaded(task) {
    const sections = this.getResult(task.id)
    if (!sections) {
      return true
    }
    return this.sections.every(section => this.sectionUploaded(section))
  },
  sectionUploaded(section) {
    if (!section.components)
      return false;
    return section.components.every(c => this.componentUploaded(c))
  },
  componentUploaded(component) {
    if (!component.images)
      return false;
    return component.images.every(img => img.task_id)
  },
  taskSynced(task) {
    const sections = this.taskResults[task.id]
    if (!sections) {
      return true
    }
    return sections.every(section => this.sectionSynced(section))
  },
  sectionSynced(section) {
    if (section.cmd)
      return false;
    if (!section.components)
      return true;
    return section.components.every(c => this.componentSynced(c))
  },
  componentSynced(component) {
    if (component.cmd)
      return false;
    if (!component.images)
      return true;
    return component.images.every(img => !img.cmd && img.task_id)
  },
  images({task_id, category}) {
    const task = this.getTask(task_id)
    if (!task) {
      return []
    }
    if (category == 'description') {
      return task.descriptionImages || []
    } else if (category == 'summary') {
      return task.summaryImages || []
    } else {
      const jsonResult = this.taskResults[task.id] || []
      for (const section of jsonResult) {
        for (const component of section.components) {
          if (category == component.id) {
            return component.images || []
          }
        }
      }
      return []
    }
  },
}

const getters = {
  filter: state => state.filter,
  todolist(state, getters, rootState, rootGetters) {
    const { showarchived, showfinished, onlyMyWorklist, onlyMyCreatedWorklist } = state.filter
    const account = rootGetters['Accounts/account']
    return state.todolist.filter(it => {
      if (it.status == TaskStatus.Archived && !showarchived) return false
      if (it.status == TaskStatus.Done && !showfinished) return false
      if (onlyMyWorklist) return it.assignee_id == account.id
      if (onlyMyCreatedWorklist) return it.creator_id == account.id
      return true
    })
  },
  alltasks: state => state.todolist,
  allPerformers: state => state.allPerformers,
  getTask: state => (task_id) => state.getTask(task_id),
  getTaskResult: state => (task_id) => state.getResult(task_id),
  imageURL: state => (task_id, fname) => {
    const task = state.getTask(task_id)
    const { house_id, title, timestamp } = task
    const dateDir = moment(timestamp*1000).format("yyyy-MM-DD");
    const imgURL = `${axios.defaults.docURL}${house_id}/${title}/${dateDir}/images/${fname}`;
    return imgURL
  },
  taskSynced: state => (task) => state.taskSynced(task),
  sectionSynced: state => (section) => state.sectionSynced(section),
  componentSynced: state => (component) => state.componentSynced(component),
  taskUploaded: state => (task) => state.taskUploaded(task),
  sectionUploaded: state => (section) => state.sectionUploaded(section),
  componentUploaded: state => (component) => state.componentUploaded(component),
  busy: state => state.busy,
  images: state => ({task_id, category}) => state.images({task_id, category}),
}

const mutations = {
  setFilter(state, {showarchived, showfinished, onlyMyWorklist, onlyMyCreatedWorklist}) {
    state.filter = {showarchived, showfinished, onlyMyWorklist, onlyMyCreatedWorklist}
  },
  setTodolist(state, data) {
    Vue.set(state, 'todolist', data)
    Vue.set(state, 'detailedTasks', {})
  },
  setPerformers(state, data) {
    Vue.set(state, 'allPerformers', data)
  },
  setDetails(state, details) {
    console.log('setDetails', details)
    const task_id = details.id
    let item = state.detailedTasks[task_id]
    if (item) {
      Object.assign(item, details)
    } else {
      item = details
    }
    Vue.set(state.detailedTasks, task_id, item)
    mutations.updateTodoList(state, {
      id: item.id,
      house_id: item.house_id,
      title: item.title,
      timestamp: item.timestamp,
      status: item.status,
      assignee_id: item.assignee_id,
      assignee: item.assignee,
      description: item.description,
      key_id: item.key_id,
      claims: item.claims,
      key: item.key,
    })
  },

  setResult(state, details) {
    // console.log('setResult', details)
    const task_id = details.id
    if (details.jsonResult) {
      Vue.set(state.taskResults, task_id, details.jsonResult)
    }
  },

  updateTodoList(state, taskUpdates) {
    const pos = state.todolist.findIndex(task => task.id === taskUpdates.id)
    const found = state.todolist[pos]
    if (found) {
      Object.assign(found, taskUpdates)
      Vue.set(state.todolist, pos, found)
    } else {
      const pos = state.todolist.push(taskUpdates) -1
      Vue.set(state.todolist, pos, taskUpdates)
    }
  },

  setReported(state, {id, status, report_file, reportURL}) {
    console.log('setReported', {id, status, report_file, reportURL})
    const task = state.detailedTasks[id]
    if (task) {
      Vue.set(task, 'status', status)
      Vue.set(task, 'report_file', report_file)
      Vue.set(task, 'reportURL', reportURL)
      mutations.updateTodoList(state, {id, status, report_file, reportURL})
    }
  },

  cancel(state, task) {
    if (task.status === 0) {
      Vue.set(task, 'status', -1)
      mutations.updateTodoList(state, {id: task.id, status: -1})
    }
  },

  schedule(state, task) {
    Vue.set(task, 'status', 0)
    mutations.updateTodoList(state, {id: task.id, status: 0, timestamp: task.timestamp})
  },

  confirm(state, task) {
    Vue.set(task, 'status', 1)
    mutations.updateTodoList(state, {id: task.id, status: 1})
  },

  startPerforming(state, task) {
    Vue.set(task, 'status', 2)
    mutations.updateTodoList(state, {id: task.id, status: 2})
  },

  revision(state, task) {
    Vue.set(task, 'status', 2)
    mutations.updateTodoList(state, {id: task.id, status: 2})
  },

  archive(state, task) {
    Vue.set(task, 'status', 10)
    mutations.updateTodoList(state, {id: task.id, status: 10})
  },

  componentAdded(state, components) {
    const pos = components.length - 1
    components[pos].cmd = 'add'
    Vue.set(components, pos, components[pos])
  },

  componentModified(state, component) {
    console.log('componentModified', component)
    if (!component.cmd) {
      Vue.set(component, 'cmd', 'modify')
    }
    if (component.area != undefined) {
      Vue.set(component, 'area', component.area)
    }
    if (component.name != undefined) {
      Vue.set(component, 'name', component.name)
    }
    if (component.note != undefined) {
      Vue.set(component, 'note', component.note)
    }
    if (component.action != undefined) {
      Vue.set(component, 'action', component.action)
    }
    if (component.status != undefined) {
      Vue.set(component, 'status', component.status)
    }
    if (component.images != undefined) {
      Vue.set(component, 'images', component.images)
    }
  },

  componentDeleted(state, component) {
    Vue.set(component, 'cmd', 'delete')
  },

  sectionAdded(state, sections) {
    const pos = sections.length - 1
    sections[pos].cmd = 'add'
    Vue.set(sections, pos, sections[pos])
  },

  sectionModified(state, section) {
    Vue.set(section, 'cmd', 'modify')
    if (section.name != undefined) {
      Vue.set(section, 'name', section.name)
    }
  },

  sectionDeleted(state, section) {
    Vue.set(section, 'cmd', 'delete')
  },

  addRoomForInspection(state, { task_id, room }) {
    const task = state.getTask(task_id)
    if (!task) {
      return
    }
    const components = room.areas.map(a => ({
      action: a.action,
      area: a.area,
      icon: a.icon,
      id: a.id,
      note: a.note,
      status: a.status,
      section_id: a.room_id,
      images: []
    }))
    Vue.set(room, 'components', components)
    Vue.set(room, 'cmd', 'add')
    delete room.areas

    const rooms = state.taskResults[task_id]
    const pos = rooms.push(room) - 1
    Vue.set(rooms, pos, room)
  },

  setBusy(state, busy) {
    Vue.set(state, 'busy', busy)
  },

  setImages(state, { task_id, category, images}) {
    const oldImages = state.images({task_id, category})
    oldImages.splice(0, oldImages.length, ...images)
  },

  addImages(state, { task_id, category, images}) {
    const oldImages = state.images({task_id, category})
    oldImages.splice(oldImages.length, 0, ...images)
  },

  removeImage(state, { task_id, category, index}) {
    const oldImages = state.images({task_id, category})
    const imgObj = oldImages[index]
    if (imgObj.src) {
      // delete imgObj.src
      oldImages.splice(index, 1)
    } else {
      Vue.set(imgObj, 'cmd', 'delete')
    }
  },

  clearReportURL(state, { task_id}) {
    const task = state.getTask(task_id);
    Vue.set(task, 'reportURL', null)
  }
}

const actions = {
  async fetchTodoList({ commit }) {
    try {
      const response = await axios.get('task/todolist')
      if (response.data.errno === 0) {
        const tasks = response.data.data
        commit('setTodolist', tasks)
      } else {
        throw new Error(response.data.errmsg)
      }
    } catch (error) {
      toast(error.message || error)
    }
  },

  async fetchPerformers({ commit }) {
    try {
      const response = await axios.get('account/team')
      if (response.data.errno === 0) {
        const performers = response.data.data;
        commit('setPerformers', performers)
      } else {
        throw new Error(response.data.errmsg)
      }
    } catch (error) {
      toast(error.message || error)
    }
  },

  async fetchDetails({ getters, commit }, task_id ) {
    if (!task_id) {
      toast('parameter task_id of fetchDetails is null')
      return null;
    }
    const task = getters.getTask(task_id);
    if (task) {
      console.log('getters.getTask: ', task)
      return task;
    }

    // fall back to get task from backend
    try {
      const params = {task_id}
      const response = await axios.get('task/getDetails', {params})
      if (response.data.errno) {
        throw new Error(response.data.errmsg)
      }
      const details = response.data.data;
      await commit('setDetails', details)
      return details;
    } catch (error) {
      toast(error.message || error)
    }
    return null
  },

  async fetchTaskResult({ getters, commit, dispatch }, task_id) {
    if (!task_id) {
      toast('parameter task_id of fetchDetails is null')
      return null;
    }
    const taskResult = getters.getTaskResult(task_id);
    if (taskResult) {
      console.log('getters.getTaskResult: ', taskResult)
      return taskResult;
    }
    const result = localStorage.getItem(`task-${task_id}`)
    if (result) {
      const jsonResult = JSON.parse(result)
      commit('setResult', {id: task_id, jsonResult})
      return
    }

    try {
      const params = {task_id}
      const response = await axios.get('task/getResult', {params})
      if (response.data.errno) {
        throw new Error(response.data.errmsg)
      }
      const data = response.data.data;
      commit('setResult', data)
      return
    } catch (error) {
      toast(error.message || error)
    }
    return null
  },

  async fetchThisWeek({ getters, commit, dispatch }) {
    const tsThisWeekStart = moment().startOf('week').unix()
    const tsThisWeekEnd = moment().endOf('week').unix()
    const tasks = getters.alltasks.filter(task => task.timestamp >= tsThisWeekStart  && task.timestamp <= tsThisWeekEnd)
    for (const task of tasks) {
      dispatch('fetchDetails', task.id)
    }
    for (const task of tasks) {
      dispatch('HouseList/fetchDetails', task.house_id, { root: true})
    }
  },

  async uploadTask({getters, commit, dispatch }, task_id) {
    let task = getters.getTask(task_id)
    if (!task) {
      toast('No task found to uploadTask.')
      return null;
    }
    try {
      await dispatch('uploadTaskImages', {task_id, category: 'description'})
      await dispatch('uploadTaskImages', {task_id, category: 'summary'})
      // await dispatch('syncTaskResult', task_id)
    } catch (error) {
      toast(error.message || error)
    }
  },

  async uploadTaskSettings({getters, commit, dispatch }, task_id) {
    let task = getters.getTask(task_id)
    if (!task) {
      toast('No task found to uploadTask.')
      return null;
    }
    try {
      await dispatch('uploadTaskImages', {task_id, category: 'description'})
      await dispatch('uploadTaskImages', {task_id, category: 'summary'})

      const taskSettings = {
        id: task.id,
        description: task.description,
        summary: task.summary,
        assignee_id: task.assignee_id,
        timestamp: task.timestamp,
        subtitle: task.subtitle,
      }
      await dispatch('uploadDetails', taskSettings)
    } catch (error) {
      toast(error.message || error)
    }
  },

  async syncTaskResult({getters, commit, dispatch}, task_id) {
    if (getters.busy) {
      confirm('Sync operation in progress')
      return
    }
    let task = getters.getTask(task_id)
    if (!task) {
      toast('No task found to syncTaskResult.')
      return null;
    }
    await confirm('Sync operation is starting')

    const jsonResult = getters.getTaskResult(task_id)
    commit('setBusy', true)
    try {
      const modifiedSections = jsonResult.filter(s => !getters.sectionSynced(s))
      for (const section of modifiedSections) { // gather images to upload
        await dispatch('syncSection', {task_id, section_id: section.id})
      }
      await dispatch('uploadResult', { id: task_id, jsonResult: modifiedSections})
    } catch (error) {
      toast(error.message)
    }
    commit('setBusy', false)
  },

  async syncSection({getters, dispatch}, {task_id, section_id}) {   // upload images only
    const task = getters.getTask(task_id)
    if (!task) {
      toast('No task found to syncArea.')
      return null;
    }
    const jsonResult = getters.getTaskResult(task_id)
    const section = jsonResult.find(s => s.id === section_id);
    if (!section) {
      toast('section_id missing.')
      return null;
    }
    for (const component of section.components) { // gather images to upload
      await dispatch('syncComponent', {task_id, component})
    }
  },

  resetReportFile({getters, dispatch}, task_id) {
    let task = getters.getTask(task_id)
    if (!task) {
      toast('No task found to syncTaskResult.')
      return null;
    }
    Vue.set(task, 'report_file', null)
  },

  async syncComponent({commit, dispatch}, {task_id, component}) { // upload images only
    // console.log('syncComponent', {task_id, category, component})
    const dirty = await dispatch('uploadTaskImages', {task_id, category: component.id})
    // console.log('component', component)
    if (dirty) {
      commit('componentModified', component)
    }
    const keyItem = `${task_id}-${component.id}`
    localStorage.removeItem(keyItem)
  },

  async generateReport({commit, getters, dispatch}, {task_id, auto_issue, contacts}) {
    if (!task_id) {
      toast('No task found to generateReport.')
    }

    try {
      let response = await axios.post(`task/generateReport`, {
        task_id,
        auto_issue,
        contacts
      })
      if (response.data.errno) {
        throw new Error(response.data.errmsg)
      }
      const responseData = response.data.data
      console.log('task/generateReport', { responseData })

      if (responseData.status === 'ERROR') {
        throw new Error(responseData.statusText)
      }

      commit('clearReportURL', {task_id})

      let selectedTask = getters.getTask(task_id)
      let countDown = 200;
      const originalReportURL = selectedTask.reportURL;
      const loopCheck = setInterval(async () => {
        await dispatch('getReportURL', task_id)
        console.log('getReportURL', task_id)
        if (selectedTask.reportURL && selectedTask.reportURL !== originalReportURL) {
          console.log({generateReport: selectedTask.reportURL})
          clearInterval(loopCheck);
          // dispatch('fetchDetails', task_id)
        }
        countDown --;
        if (countDown === 0) {
          clearInterval(loopCheck);
          console.log("Failed to get report URL after 6 minutes");
          throw new Error("Failed to get report URL after 6 minutes")
        }
      }, 3000)
      toast('Please wait for a few seconds to prepare report for you.')
    } catch (error) {
      toast(error.message || error)
    }
  },

  async uploadReport({ dispatch }, {task_id, formData}) {
    try {
      const response = await axios.post('task/uploadReport', {task_id, formData})
      if (response.data.errno === 0) {
        const responseData = response.data.data
        console.log('task/uploadReport', { responseData })
        dispatch('fetchDetails', {task_id})
        toast('Report successfully uploaded.')
      } else {
        throw new Error(response.data.errmsg)
      }
    } catch (error) {
      toast(error.message || error)
    }
  },

  async sendReport({ dispatch }, {task_id, contacts}) {
    try {
      const response = await axios.post('task/sendReport', { task_id, contacts })
      if (response.data.errno === 0) {
        dispatch('fetchDetails', task_id)
        toast('Report email has been sent successfully.')
      } else {
        throw new Error(response.data.errmsg)
      }
    } catch (error) {
      console.log('Task.sendReport', error);
      toast(error.message || error)
    }
  },

    
  async getReportURL({ getters, commit }, task_id) {
    const res = await axios.get(`task/getDetails?task_id=${task_id}&retrieve=url`);
    if (res.data.errno == 0 && res.data.data) {
      const taskUpdate = res.data.data
      commit('setReported',taskUpdate)
      console.log('setReported', {taskUpdate})
      return taskUpdate.reportURL
    }
    return null;
  },

  async archive({ commit, dispatch }, task) {
    commit('archive', task)
    await dispatch('uploadDetails', {
      id: task.id,
      status: task.status,
    })
  },

  async schedule({ commit, dispatch }, task) {
    commit('schedule', task)
    await dispatch('uploadDetails', {
      id: task.id,
      status: task.status,
      timestamp: task.timestamp,
    })
  },

  async cancel({ commit, dispatch }, task) {
    if (task.status === 0) {
      commit('cancel', task)
    }
    await dispatch('uploadDetails', {
      id: task.id,
      status: task.status,
    })
  },

  async confirm({ commit, dispatch }, task) {
    commit('confirm', task)
    await dispatch('uploadDetails', {
      id: task.id,
      status: task.status,
    })
  },

  async startPerforming({ commit, dispatch }, task) {
    console.log('startPerforming', task)
    if (task.status == 2) {
      return
    }
    commit('startPerforming', task)
    await dispatch('uploadDetails', {
      id: task.id,
      status: task.status,
    })
  },

  async revision({ commit, dispatch }, task) {
    commit('revision', task)
    await dispatch('uploadDetails', {
      id: task.id,
      status: task.status,
    })
  },

  async uploadTaskImages({ getters, commit, dispatch }, { task_id, category }) {
    const images = getters.images({ task_id, category })
    const uploadFiles = images.filter(image => !image.task_id)
    if (uploadFiles.length === 0) {
      return false;
    }

    let dirty = false
    try {
      const updatedImages = []
      for (const image of images) {
        if (image.cmd == 'delete') {
          console.log('image is deleted', image)
        } else if (image.task_id) {
          updatedImages.push(image)
        } else {
          const uploaded = await dispatch('_uploadImage', { task_id, category, image})
          updatedImages.push(...uploaded)
          dirty = true
        }
      }
      commit('setImages', { task_id, category, images: updatedImages})
      // images.length = 0;
      // images.push(...updatedImages)
    } catch (error) {
      console.log('_uploadImage', error)
      toast(`FAILED to upload images for ${category}`)
    }
    return dirty
  },

  async _uploadImage({ commit, dispatch }, { task_id, category, image}) {
    if (image.task_id || !image.name) {
      return;
    }
    // console.log('this', this)
    // console.log('this._vm', this._vm)
    // const localforage = this._vm.$localforage
    // const imageSrc = await localforage.getItem(image.name)
    if (!image.src)
      return null
    const imgresp = await fetch(image.src);
    const data = await imgresp.blob();
    const metadata = {
      type: 'image/jpeg'
    };
    const fileImage = new File([data], image.name, metadata);

    if (!fileImage) {
      console.log(`${image.name} is absent`)
      return
    }

    const formData = new FormData()
    formData.append('task_id', task_id)
    formData.append('category', category)
    formData.append('areas_id', category)
    formData.append('uploadFiles', fileImage)
    const response = await axios.post(`task/uploadTaskImages`, formData)
    if (response.data.errno) {
      throw new Error(response.data.errmsg)
    }
    const uploaded = response.data.data
    console.log('localforage.removeItem: ', image.name)
    // await localforage.removeItem(image.name)
    // image.src = null
    // delete image.src
    return uploaded
  },

  async uploadReportImages({ getter, commit, dispatch }, task) {
    const task_id = task.id;
    if (!task_id) {
      toast('No task_id to uploadReportImages.')
      return;
    }
    if (task.title==='inspections') {
      const rooms = getter.taskResults(task_id);
      for (const room of rooms) {
        for (const area of room.components) {
          const category = area.id
          const images = area.images
          if (images && images.length) {
            console.log(area)
            toast(`Uploading ${room.name}/${area.area || area.name}...`)
            await dispatch('uploadTaskImages', {task_id, category})
          }
        }
      }
    } else if (task.title==='hhs') {
      const sections = getter.taskResults(task_id);
      for (const section of sections) {
        for (const component of section.components) {
          const category = `${section.id}:${component.id}`
          const images = component.images
          if (images && images.length) {
            toast(`Uploading images for ${category}...`)
            await dispatch('uploadTaskImages', {task_id, category})
          }
        }
      }
    }
  },

  async uploadDetails({ commit, dispatch }, task) {
    console.log('uploadDetails starts...', task)
    const task_id = task.id;
    if (!task_id) {
      console.log('No task_id to uploadDetails.')
      toast('No task_id to uploadDetails.')
      return null;
    }

    const response = await axios.post(`task/update`, task)
    if (response.data.errno) {
      throw new Error(response.data.errmsg)
    }

    const newTaskDetails = response.data.data
    if (newTaskDetails) {
      console.log('uploadDetails in uploadTask', newTaskDetails)
      await commit('setDetails', newTaskDetails)
    }

    return response.data.data
  },

  async uploadResult({ commit, dispatch }, {id, jsonResult}) {
    console.log('uploadResult starts...', id)
    const task_id = id;
    if (!task_id) {
      console.log('No task_id to uploadDetails.')
      toast('No task_id to uploadDetails.')
      return null;
    }
    const response = await axios.post(`task/result`, {id, jsonResult})
    if (response.data.errno) {
      throw new Error(response.data.errmsg)
    }
    const newTaskDetails = response.data.data
    if (newTaskDetails) {
      // console.log('uploadDetails in uploadTask', newTaskDetails)
      await commit('setResult', newTaskDetails)
    }
    return response.data.data
  },
}

const storeModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default storeModule
