  <style>
    .mark {
      color: darkred;
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-around;
      width: 100%;
      padding-bottom: 9px;
    }

    .function {
      width: 50%;
      margin: 0.5rem 25%;
      height: 2.5rem;
      margin: 4px;
      flex: 1;
    }

  </style>

<template>
  <div id="page" v-if="task && house">
    <header class="mui-bar mui-bar-nav">
      <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left" :disabled="busy"></a>
      <h1 class="mui-title">Maintenance</h1>
    </header>
    <div class="mui-scroll-wrapper">
      <div class="mui-scroll">

        <MapLocator :house="house" />

        <div class="mui-content-padded" style="padding: .5em;">
          <span class="mui-badge mui-row">Task description</span>
          <textarea class="mui-row" v-model="task.description" placeholder="Task description"
            style="height: 8rem; margin: 0; font-size: .9rem; padding: 0.1rem; overflow:auto;" readonly />
          <Gallary :task="task" category="description" />
        </div>

        <div class="mui-content-padded" style="padding: .5em;">
          <span class="mui-badge mui-row">Summary of performance</span>
          <textarea class="mui-row" v-model="task.summary" placeholder="Task description"
            style="height: 8rem; margin: 0; font-size: .9rem; padding: 0.1rem; overflow:auto;" />
          <Gallary :task="task" category="summary" file camera />
        </div>

      </div>
    </div>
    <nav class="mui-bar mui-bar-tab wrapper">
      <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_cancel()'>
        <span class="tab-label">CANCEL</span>
      </button>
      <button type="button" class="tab-item mui-btn mui-btn-primary function" v-on:tap='tap_confirm()'>
        <span class="tab-label">CONFIRM</span>
      </button>
    </nav>
  </div>
</template>>

<script>

  import {toast, alert, confirm, prompt, enableScroll } from "@/assets/js/api";
  import MapLocator from "@/components/MapLocator"
  import Gallary from "@/components/Gallary"
  import {mapGetters, mapActions, mapMutations } from 'vuex'

  export default {
    name: "Perform",
    components: {
      MapLocator,
      Gallary,
    },
    data() {
      return {
        busy: false,
        house: null,
        task: null,
      }
    },
    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        fetchTaskDetails: 'Tasks/fetchDetails',
      }),
      async init() {
        const {task_id} = this.$router.currentRoute.query
        if (!task_id) {
          throw 'invalid task id'
        }
        this.task = await this.fetchTaskDetails(task_id)

        if (this.task) {
          const house_id = this.task.house_id;
          this.house = await this.fetchHouseDetails(house_id)
        }
      },

      async tap_confirm() {
      },

      async tap_cancel() {
      },

    },
    async mounted() {
      await this.init()
      enableScroll('.mui-scroll-wrapper')
    }
  }

</script>

