<template>
  <div class="home">
    <img class="logo" alt="Vue logo" src="../assets/logo.png" />
    <h1>Welcome to new Inspector App</h1>
    <br/>
    <router-link to="/signin" v-if="testing">
      Click here for testing only
    </router-link>
    <template v-if="iPhone && isSafari">
      <div class="corner" >
        Click the icon below <img style="width: 25px; height: 25px;" src="/img/hand-down.png" alt="below"/> add me to Home Screen.
      </div>
    </template>
    <template v-else>
      <div class="top-right">
        Please open it in Safari to install.
      </div>
      <div class="corner">
        This app can only be installed on iPhone and Safari.
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    name: "Home",
    data() {
      return {
      }
    },
    computed: {
      standalone() {
        return  ('standalone' in window.navigator) && (window.navigator.standalone);
      },
      testing() {
        return (window.appConfig.testing)
      },
      iPhone() {
        return navigator.platform == 'iPhone'
      },
      isSafari() {
        const is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
        return is_safari;
      }
    },
    mounted() {
      if (this.standalone) {
        this.$router.push('/signin')
      }
    }
  }
</script>

<style scoped>

  a {
    color: navy;
  }

  .home {
    padding: 4rem 1rem;
  }

  .logo {
    width: 30%;
  }

  .corner {
    position: absolute;
    bottom: 20px;
    text-align: center;
  }

  .top-right {
    width: 100%;
    position: absolute;
    top: 8px;
    display: block;
    text-align: right;
  }
</style>