import Vue from 'vue'
import axios from 'axios'
import {toast} from "@/assets/js/api";

export default {
  namespaced: true,
  state: {
    houseList: [],
    houseDetails: {},
    getHouse(house_id) {
      return this.houseDetails[house_id];
    },
    getRoom({house_id, room_id}) {
      const house = this.getHouse(house_id);
      if (house && house.room && house.room.length > 0) {
        const room = house.room.find(r => r.id == room_id)
        if (room && room.areas.length > 0) {
          room.condition = room.areas.every(a => !!a.status)? 'Complete' : 'Incomplete'
          // console.log('after checking every area', room)
        }
        return room
      }
      return null;
    },
    getArea({house_id, room_id, areas_id}) {
      const room = this.getRoom({house_id, room_id});
      if (room && room.areas.length > 0) {
        return room.areas.find(a => a.id == areas_id)
      }
      return null;
    }
  },
  getters: {
    houseList: (state) => {
      return state.houseList
    },
    getHouse: (state) => (house_id) => {
      return state.getHouse(house_id) || {}
    },
    getRoom: (state) => ({house_id, room_id}) => {
      return state.getRoom({house_id, room_id})
    },
    getArea: (state) => ({house_id, room_id, areas_id}) => {
      return state.getArea({house_id, room_id, areas_id})
    },
  },
  mutations: {
    setHouseList(state, houselist) {
      Vue.set(state, 'houseList', houselist)
      Vue.set(state, 'houseDetails', {})
    },

    setHouse(state, data) {
      const house_id = data.id
      Vue.set(state.houseDetails, house_id, data)
    },

    deleteHouse(state, house_id) {
      if (!house_id) {
        return;
      }
      delete state.houseDetails[house_id]
      const houseIndex = state.getHouse(house_id);
      if (houseIndex >= 0) {
        state.houseList.splice(houseIndex, 1);
        Vue.set(state, 'houseList', state.houseList)
      }
    },

    addHouse(state, house) {
      const pos = state.houseList.push(house) - 1;
      Vue.set(state.houseList, pos, house)
    },

    addRoom(state, room) {
      const { house_id } = room;
      if (!house_id) {
        return;
      }
      const house = state.getHouse(house_id);
      if (!house || !house.room) {
        return;
      }
      const pos = house.room.push(room) - 1
      Vue.set(house.room, pos, room)
    },

    renameRoom(state, { house_id, room_id, name }) {
      if (!room_id || !house_id) {
        return;
      }
      const room = state.getRoom({house_id, room_id});
      if (!room) {
        return;
      }
      Object.assign(room, {name})
    },

    deleteRoom(state, { house_id, room_id}) {
      if (!house_id) {
        return;
      }
      const house = state.getHouse(house_id);
      if (!house || !house.room || !house.room.length) {
        return;
      }
      const roomIndex = house.room.findIndex(r => r.id === room_id);
      if (roomIndex >= 0) {
        house.room.splice(roomIndex, 1);
      }
    },

    addArea(state, { house_id, room_id, area}) {
      console.log({house_id, room_id, area});
      if (!room_id || !house_id) {
        return;
      }
      const room = state.getRoom({house_id, room_id});
      console.log({room, area});
      if (!room || !room.areas) {
        return;
      }
      room.areas.push(area)
      console.log({room, area});
    },

    renameArea(state, { house_id, room_id, areas_id, name }) {
      if (!room_id || !house_id || !areas_id) {
        return;
      }
      const area = state.getArea({ house_id, room_id, areas_id});
      if (!area) {
        return;
      }
      Object.assign(area, {area: name})
    },

    deleteArea(state, { house_id, room_id, areas_id}) {
      if (!room_id || !house_id || !areas_id) {
        return;
      }
      const room = state.getRoom({house_id, room_id});
      if (!room || !room.areas) {
        return;
      }
      const areaIndex = room.areas.findIndex(a => a.id === areas_id);
      if (areaIndex >= 0) {
        room.areas.splice(areaIndex, 1);
      }
    },

    createTask(state, task) {
      const { house_id } = task;
      if (!house_id) {
        return;
      }
      const house = state.getHouse(house_id);
      if (!house || !house.task) {
        return;
      }
      house.task.push(task);

      console.log({state})
    }
  },
  actions: {
    async list({ commit }) {
      try {
        const response = await axios.get('address/findAddress')
        if (response.data.errno) {
          toast(response.data.errmsg)
          return;
        }
        const houses = response.data.data
        await commit('setHouseList', houses);
      } catch (error) {
        toast(error.message || error)
      }
    },
    async addHouse({ commit, dispatch }, house) {
      try {
        const response = await axios.post('house/post', house)
        if (response.data.errno === 0) {
          const house = response.data.data
          await commit('addHouse', house)
          await dispatch('list')
          toast('Successfully added the house')
          return house
        } else {
          toast(response.data.errmsg)
        }
      } catch (error) {
        toast(error.message || error)
      }
      return null;
    },
    async updateHouse({ commit, dispatch }, house) {
      const houseClone = {...house};

      delete houseClone.lease;
      delete houseClone.room;
      delete houseClone.report;
      delete houseClone.files;
      delete houseClone.fee;
      delete houseClone.pay;
      delete houseClone.lease;
      delete houseClone.pm;
      delete houseClone.task;
      delete houseClone.issue;

      try {
        const response = await axios.post('house/update', houseClone)
        if (response.data.errno) {
          toast(response.data.errmsg)
          return;
        }
        const house = response.data.data
        await commit('setHouse', house);
        await dispatch('list')
      } catch (error) {
        toast(error.message || error)
      }
    },
    async deleteHouse({ commit }, house_id) {
      try {
        const response = await axios.post('house/delete', {id: house_id})
        if (response.data.errno) {
          toast(response.data.errmsg)
          return;
        }
        await commit('deleteHouse', house_id);
      } catch (error) {
        toast(error.message || error)
      }
    },
    async fetchDetails({ getters, commit }, house_id) {
      if (!house_id) {
        console.log('parameter house_id of fetchDetails is null')
        return null;
      }
      const house = getters.getHouse(house_id);
      if (house && house.id) {
        console.log('fetchDetails get', house)
        return house;
      }

      try {
        let response = await axios.get('house/findById', {params:{id: house_id}})
        if (response.data.errno) {
          toast(response.data.errmsg)
          return null;
        }
        const house = response.data.data;
        await commit('setHouse', house);
        return house;
      } catch (error) {
        toast(error)
        return null;
      }
    },
    async addRoom({ commit }, room) {
      const { house_id } = room;
      if (!house_id) {
        toast('Can\'t add room, no house_id provided.')
        return null;
      }
      delete room.id;

      try {
        const response = await axios.post(`house/${house_id}/room`, room)
        if (response.data.errno) {
          throw new Error(response.data.errmsg)
        }
        room = response.data.data
        await commit('addRoom', room)
        return room;
      } catch (error) {
        toast(error.message || error)
        return null
      }
    },
    async renameRoom({ commit }, room) {
      try {
        const house_id = room.house_id
        const response = await axios.post(`house/${house_id}/room`, {id:room.id, name: room.name})
        if (response.data.errno) {
          throw new Error(response.data.errmsg)
        }
        await commit('renameRoom', {house_id, room_id: room.id, name: room.name})
        return true
      } catch (error) {
        toast(error.message || error)
        return false
      }
    },
    async deleteRoom({ commit }, room) {
      try {
        const { house_id, id} = room
        const response = await axios.delete(`house/${house_id}/room/${id}`)
        if (response.data.errno) {
          throw new Error(response.data.errmsg)
        }
        await commit('deleteRoom', {house_id, room_id: id})
      } catch (error) {
        toast(error.message || error)
      }
    },
    async addArea({ commit }, { house_id, room_id, area}) {
      if (!house_id || !room_id) {
        toast('Can\'t add room, no house_id or room_id provided.')
        return;
      }
      area.room_id = room_id
      delete area.id

      try {
        const response = await axios.post(`room/${room_id}/areas`, area)
        if (response.data.errno) {
          throw new Error(response.data.errmsg)
        }
        area = response.data.data
        await commit('addArea', { house_id, room_id, area})
      } catch (error) {
        toast(error.message || error)
      }
    },
    async renameArea({ commit }, { house_id, room_id, areas_id, name }) {
      try {
        const response = await axios.post(`room/${room_id}/areas`, {id:areas_id, area: name})
        if (response.data.errno) {
          throw new Error(response.data.errmsg)
        }
        await commit('renameArea', {house_id, room_id, areas_id, name})
      } catch (error) {
        toast(error.message || error)
      }
    },
    async deleteArea({ commit }, { house_id, room_id, areas_id}) {
      try {
        const response = await axios.delete(`room/${room_id}/areas/${areas_id}`)
        if (response.data.errno) {
          throw new Error(response.data.errmsg)
        }
        await commit('deleteArea', {house_id, room_id, areas_id})
      } catch (error) {
        toast(error.message || error)
      }
    },
    async createTask({ commit }, { house_id, timestamp, title, subtitle }) {
      try {
        let res = await axios.post('task/create', { house_id, timestamp, title, subtitle })
        if (res.data.errno !== 0) {
          throw { message: 'Failed to create a task' }
        }
        const task = res.data.data;
        await commit('createTask', task)
        await commit('Tasks/setDetails', task, {root: true})
      } catch (error) {
        toast(error.message || error)
      }
    },
    async setIssue({ commit }, issue) {
      try {
        const res = await axios.post('issue/post', issue);
        if (res.data.errno != 0) {
          throw 'Failed to update issue, please try again'
        }
      } catch (error) {
        toast(error.message || error)
      }
    },
    async postKey({ dispatch }, key) {
      try {
        const res = await axios.post('key', key);
        if (res.data.errno != 0) {
          throw 'Failed to record key use, please try again'
        }
        return res.data.data
      } catch (error) {
        toast(error.message || error)
      }
      return {}
    }
  },
}

