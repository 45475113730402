import Vue from 'vue'
import axios from 'axios'
import {toast} from "@/assets/js/api";

export default {
  namespaced: true,
  state: {
    token: '',
    account: {}
  },
  getters: {
    account: (state) => state.account,
    token: (state) => state.token,
  },
  mutations: {
    setContext(state, {token, account}) {
      Vue.set(state, 'token', token)
      Vue.set(state, 'account', account)
    },
  },
  actions: {
    async signIn({ commit, dispatch }, {account, password }) {
      try {
        const response = await axios.post('account/login', {account, password })
        if (response.data.errno === 0) {
          const token = response.headers.token
          const account = response.data.data
          await commit('setContext', {token, account})
        } else {
          toast(response.data.errmsg)
        }
      } catch (error) {
        toast(error.message || error)
      }
      return null;
    },

    async signOut({ commit, dispatch }) {
      commit('setContext', {token: '', account: {}})
    }
  },
}

