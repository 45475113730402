<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {},
  mounted() {
    document.title = window.appConfig.title
  },
  methods: {
    ...mapActions({
      init: 'Accounts/init',
    }),
  },
}
</script>

<style lang="scss">
  $material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
  @import '~material-design-icons-iconfont/src/material-design-icons';

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

    #nav {
      padding: 30px;

      a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
          color: #42b983;
        }
      }
    }

    .mui-content {
      // position: fixed;
      // left: 0;
      // top: 1rem;
      // width: 100%;
    }

    .mui-scroll-wrapper {
    	background-color: #efeff4;
      position:fixed;
      top: 2.7rem;
      left: 0;
    }

  	.mui-bar-nav {
  		background-color: #0000EE;
  		background: -webkit-linear-gradient(top, rgba(90, 153, 202, 1), rgba(146, 196, 235, 1));
  		color: #FFF;
  	}

  	.mui-title {
  		color: #fff;
  	}

  	.mui-bar .mui-btn-link {
  		color: white;
  	}

  	a{
  		color: white;
  	}


    input[type=text],
    input[type=number] {
      border: 0px;
      width: auto;
      margin-bottom: 2px;
      padding: 5px 10px;
      font-size: 14px;
      height: 30px;
    }

    input[type='file'] {
      background: rgb(0, 0, 0);
      border-radius: 5px;
      color: rgb(255, 255, 255);
      font-family: 'Lucida Grande';
      padding: 0px 0px 0px 0px;
      font-size: 16px;
      margin-bottom: 20px;
      width: 210px;
      vertical-align: top;
      margin-top: -2px;
      -webkit-appearence: none;
    }

    .icon {
      width: 1.2rem;
      height: 1.2rem;
    }

    .mui-popover-arrow {
      display: none;
    }

    .mui-popover {
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    #icon .mui-table-view-cell>a {
      width: 85%;
      margin-left: 1rem;
    }

  	.mui-pull-top-tips {
  		position: absolute;
  		top: -20px;
  		left: 50%;
  		margin-left: -25px;
  		width: 40px;
  		height: 40px;
  		border-radius: 100%;
  		z-index: 2;
  	}

  	.mui-bar~.mui-pull-top-tips {
  		top: 24px;
  	}

    .mui-bar~.mui-content {
      top: 44px;
      height: auto;
      background-color: #efeff4;
    }

  	.mui-pull-top-wrapper {
  		width: 42px;
  		height: 42px;
  		display: block;
  		text-align: center;
  		background-color: #efeff4;
  		border: 1px solid #ddd;
  		border-radius: 25px;
  		background-clip: padding-box;
  		box-shadow: 0 4px 10px #bbb;
  		overflow: hidden;
  	}

  	.mui-pull-top-tips.mui-transitioning {
  		-webkit-transition-duration: 200ms;
  		transition-duration: 200ms;
  	}

  	.mui-pull-top-tips .mui-pull-loading {
  		/*-webkit-backface-visibility: hidden;
  		-webkit-transition-duration: 400ms;
  		transition-duration: 400ms;*/
  		margin: 0;
  	}

  	.mui-pull-bottom-tips {
  		text-align: center;
  		background-color: #efeff4;
  		font-size: 15px;
  		line-height: 40px;
  		color: #777;
  	}

  	.mui-pull-top-canvas {
  		overflow: hidden;
  		background-color: #fafafa;
  		border-radius: 40px;
  		box-shadow: 0 4px 10px #bbb;
  		width: 40px;
  		height: 40px;
  		margin: 0 auto;
  	}

  	.mui-pull-top-canvas canvas {
  		width: 40px;
  	}

  	.mui-off-canvas-right {
  		color: #fff;
  	}

    .tab-label {
      color: primary
    }

  .middle {
    margin: auto;
  }


</style>
