<template>
  <div class="mui-table-view-cell gallary" v-if="images && task">
    <div class="mui-row" style="text-align:center; margin-top: .5rem;">
      <div class="imgDiv" v-for="(imgObj, index) in livingImges" :key="index">
          <img :class="activeObj === imgObj? 'img-active': 'img'" :src="imgSrc(imgObj)" v-on:tap="tap_img(imgObj)"/>
          <span class="mui-badge delete" v-on:tap="tap_img_delete(imgObj)">X</span>
      </div>
    </div>
    <div class="mui-row" style="text-align:center; margin-top: .5rem;">
      <svg style="width:2.2rem; height:2.2rem; margin-top:2rem; margin-left:2rem;" aria-hidden="true" v-on:tap="tap_camera()" v-if="camera">
        <use xlink:href="#icon--xiangji"></use>
      </svg>
  		<svg style="width:2.2rem; height:2.2rem; margin-top:2rem; margin-left:2rem;" aria-hidden="true" v-on:tap="tap_gallery()" v-if="file">
  			<use xlink:href="#icon--zhaopian"></use>
      </svg>
      <input v-show="false" ref="areaGallery" type="file" accept="image/*" multiple @change="finish_selectImages($event)">
      <input v-show="false" ref="areaImage" type="file" accept="image/*" multiple @change="finish_selectImages($event)" capture="camera">
      <!--<fullscreen-view-camera v-show="showCamera" ref="camera" startOnMounted=false @snap="snap()" @close="close()"></fullscreen-view-camera> -->
    </div>
    <!--
    <EasyCamera v-show="showCamera" ref="camera" startOnMounted=false @input="input($event)" @close="close()"></EasyCamera>
    -->
  </div>
</template>

<script>
  import Vue from "vue";
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import {compressFile, readAsDataURL, generateUID } from "@/assets/js/compress";
  import {toast, alert, confirm, prompt, sleep } from "@/assets/js/api";
  // import FullscreenViewCamera from './FullscreenViewCamera'
  // import EasyCamera from '@/components/EasyCamera';

  export default {
    name: "Gallary",
    components: {
      // 'fullscreen-view-camera': FullscreenViewCamera
      // EasyCamera
    },
    props: {
      task: {
        type: Object,
        required: true,
        validator: v => v.house_id && v.timestamp && v.title
      },
      category: {
        type: [String, Number],
        required: true,
      },
      file: {
        type: Boolean,
      },
      camera: {
        type: Boolean,
      }
    },
    data() {
      return {
        activeObj: null,
        busy: false,
        srcDict: {},
        picture: {},
        showCamera: false,
      }
    },
    computed: {
      ...mapGetters({
        imageURL: 'Tasks/imageURL',
        getImages: 'Tasks/images',
      }),
      images() {
        return this.getImages({ task_id: this.task.id, category: this.category })
      },
      livingImges() {
        return this.images.filter(img => img.cmd != 'delete')
      }
    },
    methods: {
      ...mapMutations({
        removeImage: 'Tasks/removeImage',
        addImages: 'Tasks/addImages',
      }),
      imgSrc (imgObj) {
        if (imgObj.task_id) {
          return this.imageURL(imgObj.task_id, imgObj.name)
        } else if (imgObj.src) {
          return imgObj.src
        } else {
          return '/img/logo.png'
        }
      },

      tap_img(imgObj) {
        this.activeObj = (this.activeObj === imgObj) ? null : imgObj
      },

      tap_camera () {
        this.$refs.areaImage.click()
      },

      tap_gallery () {
        this.$refs.areaGallery.click()
      },

      async finish_selectImages (event) {
        const localFiles = event ? event.target.files : null
        if (!localFiles || localFiles.length == 0) {
          return
        }
          await this.prepareImages(localFiles)
      },

      async tap_img_delete (imgObj) {
        const index = this.images.findIndex(img => img.name == imgObj.name)
        this.removeImage({task_id: this.task.id, category: this.category, index})
      },

      async prepareImages (localFiles) {
        try {
          for (const f of localFiles) {
            const file = await compressFile(f)
            const src = await readAsDataURL(file)
            const filename = generateUID() + '.jpeg'

            const images = [{name: filename, src}]
            this.addImages({task_id: this.task.id, category: this.category, images})
          }
        } catch (e) {
          console.log(e)
          toast(e.message || e)
        }
      },
      async run(func) {
        try {
          this.busy = true
          await func()
          return true;
        } catch (e) {
          console.log(e)
          toast(e.message || e)
          return false;
        } finally {
          this.busy = false
        }
      },
    },
  }

</script>

<style scoped>

  .gallary {
    padding: 0;
    min-height: 500px;
  }

  .imgDiv .delete {
    position: absolute;
    float: right;
    font-size: 10px;
    line-height: 1.4;
    color: white;
    background-color: orangered;
    opacity: 0.75;
    z-index: 100;
    margin-left: -20px;
    left: 100%;
    padding: 1px 5px;
  }

  .img {
    width: 90px;
    height: 120px;
  }

  .img-active {
    width: 360px;
    height: 480px;
  }

  .imgDiv {
    display: inline-block;
    position: relative;
    margin-left: 0.2rem;
    padding: 0.2rem
  }
</style>
