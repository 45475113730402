import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'
import authHelper from "@/helpers/auth"

import Home from "@/views/Home.vue";
import SignIn from "@/views/signin.vue";
import Settings from "@/views/account/settings.vue";

import HouseList from "@/views/house/houselist.vue";
import House from "@/views/house/house.vue";
import Room from "@/views/house/room.vue";

import Todolist from "@/views/task/todolist.vue";
import Taskboard from "@/views/task/taskboard.vue";
import Perform from "@/views/maintenance/perform.vue";
import Finish from "@/views/task/finish.vue";
import Viewsend from "@/views/task/viewsend.vue";

import Inspection from "@/views/inspections/inspection.vue";
import CheckRoom from "@/views/inspections/checkroom.vue";
import CheckArea from "@/views/inspections/checkarea.vue";

import HHSReport from "@/views/hhs/HHSReport.vue";
import HHSComponent from "@/views/hhs/HHSComponent.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home
  }, {
    path: "/signin",
    name: "SignIn",
    component: SignIn
  }, {
    path: "/houses",
    name: "HouseList",
    component: HouseList,
  }, {
    path: "/houses/:house_id",
    name: "House",
    component: House,
  }, {
    path: "/houses/:house_id/room/:room_id",
    name: "Room",
    component: CheckRoom,
  }, {
    path: "/inspection",
    name: "Inspection",
    component: Inspection,
  }, {
    path: "/checkroom",
    name: "CheckRoom",
    component: CheckRoom,
  }, {
    path: "/checkarea",
    name: "CheckArea",
    component: CheckArea,
  }, {
    path: "/hhsreport",
    name: "HHSReport",
    component: HHSReport,
  }, {
    path: "/hhscomponent",
    name: "HHSComponent",
    component: HHSComponent,
  }, {
    path: "/todolist",
    name: "Todolist",
    component: Todolist,
  }, {
    path: "/taskboard",
    name: "Taskboard",
    component: Taskboard,
  }, {
    path: "/finish",
    name: "Finish",
    component: Finish,
  }, {
    path: "/viewsend",
    name: "Viewsend",
    component: Viewsend,
  }, {
    path: "/perform",
    name: "Perform",
    component: Perform,
  }, {
    path: "/settings",
    name: "settings",
    component: Settings,
  }, {
    path: "/about",
    name: "About",
    component: () => import("@/views/account/aboutus.vue")
  }];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  next()
  // if (authHelper.isAuthenticated() || to.name === 'SignIn') {
  //   next()
  // } else {
  //   next('/signin')
  // }
})



// let isFirstTransition = true;
// let LS_ROUTE_KEY = 'LS_ROUTE_KEY'

// router.beforeEach((to, from, next) => {
//   const lastRoutePath = localStorage.getItem(LS_ROUTE_KEY);
//   console.log('to.name', to.name)
//   console.log('lastRoutePath', lastRoutePath)
//   const shouldRedirect = Boolean(to.name === "Home" && lastRoutePath && isFirstTransition);

//   if (shouldRedirect) next(lastRoutePath);
//   else next();

//   isFirstTransition = false;
// });

// router.afterEach(to => {
//   if (to.name != "Home") {
//     localStorage.setItem(LS_ROUTE_KEY, to.fullPath);
//   }
// });

export default router;
