
<template>
  <div id="page" v-if="house">
    <header class="mui-bar mui-bar-nav">
      <a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
      <h1 class="mui-title">{{title}}</h1>
      <span v-if="!isEdit" class="mui-icon material-icons mui-pull-right" v-on:tap='tap_edit()'>list_alt</span>
      <span v-else class="mui-icon material-icons mui-pull-right" v-on:tap='tap_done()'>done_outline</span>
    </header>
    <div class="mui-content">
      <div class="mui-scroll-wrapper">
        <div class="mui-scroll">
          <template v-if="isEdit">
            <div class="mui-content-padded">
              <span class="mui-badge mui-pull-left" style="margin-bottom: .3rem; margin-top: .5rem;">Address</span>
              <span class="mui-badge mui-pull-right" style="font-size: large; color: navy">{{house.id}}</span>
              <div class="mui-input-row">
                <input style="width: 19%;" type="text" placeholder="Unit" v-model="house.unit" :readonly="!isEdit">
                <input style="width: 19%;" type="text" placeholder="Num" v-model="house.num" :readonly="!isEdit">
                <input style="width: 58%;" type="text" placeholder="Road" v-model="house.street" :readonly="!isEdit">
              </div>
              <div class="mui-input-row">
                <input style="width: 39%;" type="text" placeholder="Suburb" v-model="house.suburb">
                <input style="width: 58%;" type="text" placeholder="City" v-model="house.city">
              </div>

              <div style="margin-top: .5rem;">
                <span class="mui-badge mui-pull-left" style="margin-bottom: .3rem;">Owner</span>
                <div class="mui-input-row">
                  <input style="width: 39%;" type="text" placeholder="First Name" v-model="house.owner_firstname" :readonly="!isEdit">
                  <input style="width: 58%;" type="text" placeholder="Surname" v-model="house.owner_lastname" :readonly="!isEdit">
                </div>
                <div class="mui-input-row">
                  <input style="width: 39%;" type="number" placeholder="Phone Number" v-model="house.owner_phone" :readonly="!isEdit">
                  <input style="width: 58%;" type="text" placeholder="Email" v-model="house.owner_email" :readonly="!isEdit">
                </div>
              </div>

              <div style="margin-top: .5rem;">
                <div class="mui-row">
                  <span class="mui-badge mui-pull-left">Rooms</span>
                  <span class="mui-icon material-icons mui-pull-right" style="color:blue;" aria-hidden="true" v-on:tap="tap_add_room()">add_circle_outline</span>
                </div>
                <ul class="mui-table-view">
                  <li class="mui-table-view-cell" v-for="room in visibleRooms" :key="room.id" v-on:tap='tap_room(room)'>
                    <div class="mui-slider-right mui-disabled">
                      <a class="mui-btn mui-btn-red" v-on:tap.stop='tap_room_delete(room,$event)'>DELETE</a>
                    </div>
                    <div class="mui-slider-left mui-disabled">
                      <a class="mui-btn mui-btn-blue" v-on:tap.stop='tap_room_rename(room,$event)'>RENAME</a>
                    </div>
                    <div class="mui-slider-handle middle">
                      <svg class="icon mui-pull-left" aria-hidden="true">
                        <use v-bind="{'xlink:href':room.icon}"></use>
                      </svg>
                      <span class="mui-pull-left">{{room.name}}</span>
                      <span class="mui-icon material-icons mui-pull-right">navigate_next</span>
          						<span class="mui-pull-right" v-if="task_id">{{condition(room)}}</span>
                    </div>
                  </li>
                </ul>

                <div id="add_room" class="mui-popover">
            			<div class="mui-popover-arrow"></div>
                  <div class="mui-scroll-wrapper" style="height: 55rem; margin: 0px;">
                    <div class="mui-scroll">
                      <ul class="mui-table-view">
                        <li v-for="rt in roomTemplates" :key="rt.label" class="mui-table-view-cell" v-on:tap='tap_room_template(rt)'>
                          <svg class="icon mui-pull-left" aria-hidden="true">
                            <use :xlink:href="rt.xlink_href"></use>
                          </svg>
                          <span class="mui-pull-left" style="margin-left: 1rem;">
                            {{rt.label}}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>

              <br />
              <br />
            </div>
          </template>
          <template v-else>
            <HouseGlance :house="house" />
            <div class="mui-table-view">
              <div style="margin-top: 1.1rem;">
                <div class="mui-content-padded" style="padding: 1rem; margin-bottom: 1.5rem; display: flex; align-items: center;">
                  <!-- <div id="reportIssues" class="mui-switch mui-pull-left mui-switch-red  mui-active" style="height: 1.5em;" v-on:toggle="tap_switch_history()">
                    <div class="mui-switch-handle" style="height: 1.4em;">
                    </div>
                  </div>
                  !-->
                  <label class="switch">
                    <input type="checkbox" v-model="viewHistoryTasks">
                    <span class="slider round"></span>
                  </label>

                  <span class="" style="font-size: medium; color: navy; padding: 1rem;">{{historyStatus}}</span>
                </div>

                <slot v-if='!viewHistoryTasks'>
                  <div style="margin-top: .5rem;" v-if="ongoingTasks.length > 0">
                    <div class="mui-text-center" v-for="ongoingTask in ongoingTasks" :key="ongoingTask.id">
                      <button style="margin-top: .5rem; height: 90%; width: 95%;" type="button" class="mui-btn" v-bind:class="taskBKColor(ongoingTask)" v-on:tap='tap_do_task(ongoingTask)'>
                        <span class="tab-label mui-pull-left" >{{taskLabel(ongoingTask)}}</span>
                      </button>
                    </div>
                  </div>
                </slot>

                <slot v-else>
                  <div style="margin-top: .5rem;">
                    <div class="mui-text-center" v-for="historyTask in historyTasks" :key="historyTask.id">
                      <button style="margin-top: .5rem; height: 90%; width: 95%; color: blue;" type="button" class="mui-btn mui-btn-grey" v-on:tap='tap_do_task(historyTask)'>
                        <span class="tab-label mui-pull-left">{{taskLabel(historyTask)}}</span>
                      </button>
                    </div>
                  </div>
                </slot>
              </div>

            </div>
          </template>
        </div>

      </div>
      <nav class="mui-bar mui-bar-tab">
        <div class="wrapper">
          <button type="button" class="mui-btn mui-btn-yellow function" v-on:tap='tap_book_inspect()' :disabled="pending('inspections')">
            <span class="tab-label">INSPECTION</span>
          </button>
          <button type="button" class="mui-btn mui-btn-green function" v-on:tap='tap_book_maintenence()'>
            <span class="tab-label">Maintenence</span>
          </button>
          <button type="button" class="mui-btn mui-btn-purple function" v-on:tap='tap_book_hhs_check()' :disabled="pending('hhs')">
            <span class="tab-label">HHS CHECK</span>
          </button>
        </div>
      </nav>

      <selectDueDate ref="selectDueDate"/>

    </div>
  </div>
</template>

<script>

  // @ is an alias to /src
  import Vue from "vue";
  import mui from "@/assets/js/mui.min";
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import moment from "moment";
  import {toast, alert, confirm, prompt, popover, enableScroll } from "@/assets/js/api";
  import selectDueDate from "@/components/selectDueDate"
  import HouseGlance from "@/components/HouseGlance"
  // import RoomsView from "@/components/RoomsView"

  export default {
    name: "House",
    components: {
      selectDueDate,
      HouseGlance,
//      RoomsView,
    },
    data() {
      return {
        isEdit: false,
        viewHistoryTasks: false,
        house: null,
        roomTemplates: [
          { xlink_href: '#icon-jiajiabaojie-tubiao-', label: 'Bedroom' },
          { xlink_href: '#icon-canting', label: 'Dinning Room' },
          { xlink_href: '#icon-keting1', label: 'Living Room' },
          { xlink_href: '#icon-youxiji--', label: 'Play Room' },
          { xlink_href: '#icon-chufangyongju', label: 'Kitchen' },
          { xlink_href: '#icon-weishengjian', label: 'Toilet' },
          { xlink_href: '#icon-xiyiji', label: 'Laundry' },
          { xlink_href: '#icon-tingcheruku', label: 'Garage' },
          { xlink_href: '#icon-keting1', label: 'Lounge' },
          { xlink_href: '#icon-men', label: 'Entrance/Hall' },
          { xlink_href: '#icon-Other', label: 'General' },
          { xlink_href: '#icon-Other', label: 'Other' },
        ],
      }
    },
    computed: {
      ...mapGetters({
        alltasks: 'Tasks/alltasks',
      }),
      house_id() {
        return this.house? null : this.house.id;
      },
      visibleRooms() {
        return this.house.room.filter(room => room.cmd != 'delete')
      },
      historyTasks() {
        return this.house.task.filter(t => t.status >= 9)
      },
      ongoingTasks() {
        return this.alltasks.filter(t => t.house_id == this.house.id && t.status >= 0 && t.status < 9)
      },
      historyStatus() {
        return this.viewHistoryTasks ? 'Switch back to taskboard' : 'Switch to review history'
      },
      title() {
        return this.house? `${this.house.num} ${this.house.street}`: 'New';
      },
      pending() {
        return (title) => this.ongoingTasks.some(t => t.title === title)
      },
      taskLabel() {
        return ongoingTask => {
          const statusDir = {
            0: 'scheduled',
            1: 'confirmed',
            2: 'performing',
            7: 'reporting',
            8: 'finished',
            9: 'reported',
            10: 'archived'
          }
          const date = moment(ongoingTask.timestamp * 1000).format('dddd YYYY-MM-DD')
          const status = statusDir[ongoingTask.status];
          const label = `${ongoingTask.title} ${status} on ${date}`;
          return label;
        }
      },
      taskBKColor() {
        return ongoingTask => {
          switch (ongoingTask.status) {
            case 0: return 'mui-badge-yellow';
            case 1: return 'mui-badge-green';
            case 2: return 'mui-badge-red';
            case 8: return 'mui-badge-purple';
            case 9: return 'mui-badge-grey';
          }
        }
      }
    },

    methods: {
      ...mapActions({
        fetchHouseDetails: 'HouseList/fetchDetails',
        updateHouse: 'HouseList/updateHouse',
        createTask: 'HouseList/createTask',
      }),
      ...mapMutations({
        roomAdded: 'Tasks/sectionAdded',
        roomModified: 'Tasks/sectionModified',
        roomDeleted: 'Tasks/sectionDeleted',
      }),

      tap_edit() {
        setTimeout(()=> this.isEdit = true, 150)
      },

      async check_input() {
        if (!this.house.num) {
          alert('Street number cannot be empty.')
          return false
        }
        if (!this.house.street) {
          alert('Street name cannot be empty.')
          return false
        }
        return true
      },

      async update() {
        if (await this.check_input()) {
          const houseClone = { ...this.house };
          delete houseClone.lease;
          delete houseClone.room;
          delete houseClone.report;
          delete houseClone.files;
          delete houseClone.fee;
          delete houseClone.pay;
          delete houseClone.lease;
          delete houseClone.pm;
          delete houseClone.task;
          delete houseClone.issue;

          const res = await this.updateHouse(houseClone)
          this.isEdit = false
          return true
        }
        return false
      },

      async tap_done() {
        await this.update()
      },

      tap_switch_history() {
        console.log('', {tap_switch_history: this.viewHistoryTasks})
        this.viewHistoryTasks = !this.viewHistoryTasks;
      },

      async tap_book_inspect() {
        const timestamp = await this.$refs.selectDueDate.show('Create Inspection task');
        await this.createNewTask({ timestamp, title: 'inspections'})
      },

      async tap_book_hhs_check() {
        const timestamp = await this.$refs.selectDueDate.show('Create HHS task');
        await this.createNewTask({ timestamp, title: 'hhs'})
      },

      async tap_book_maintenence() {
        const timestamp = await this.$refs.selectDueDate.show('Create Maintenance task');
        await this.createNewTask({ timestamp, title: 'maintenance'})
      },

      async createNewTask({timestamp, title}) {
        if (!timestamp) {
          return;
        }
        let subtitle = '';
        if (title === 'inspections') {
          const e =  await confirm('', `Is it a moving in/out report ?`, ['NO', 'YES'])
          if (e.index == 0) {
            subtitle = 'normal';
          } else {
            subtitle = 'moving_inout';
          }
        }

        await this.createTask({
          house_id: this.house.id,
          title,
          subtitle,
          timestamp,
          assignee_id: this.house.pm ? this.house.pm.id: null,
        })
      },

      async tap_do_task(task) {
        switch (task.status) {
          case 0:   // scheduled task
          case 1:   // confirming task
          case 7:   // reporting
          case 8:   // report ready
          case 9:   // report sent
          case 10:   // closed
            this.$router.push(`/taskboard?task_id=${task.id}`)
            break;
          case 2:   // performing task
            if (task.title === 'inspections') {
              this.$router.push(`/inspection?task_id=${task.id}`)
            } else if (task.title === 'hhs') {
              this.$router.push(`/hhsreport?task_id=${task.id}`)
            } else if (task.title === 'maintenance') {
              this.$router.push(`/perform?task_id=${task.id}`)
            }
            break;
        }
      },


      tap_add_room() {
        popover('add_room', true)
      },

      async tap_room_template(rt) {
        popover('add_room', false)
        const newRoom = {
          house_id: this.house_id,
          id: this.rooms.reduce((min, room) => Math.min(min, room.id), 0) -1,
          name: rt.label,
          icon: rt.xlink_href
        };
        if (newRoom.name.indexOf('Other') != -1) {
          const e = await prompt('', '', 'Name', ['CANCEL', 'OK']);
          if (e.index == 1) {
            newRoom.name = e.value.trim()
          }
        }
        this.rooms.push(newRoom)
        this.roomAdded(this.rooms)
      },

      async tap_room_delete(room, el) {
        if (el) {
          var li = el.currentTarget.parentNode.parentNode
          mui.swipeoutClose(li)
        }
        const e = await confirm('DELETE', `Are you sure to delete this room of ${room.name}`, ['NO', 'YES']);
        if (e.index == 1) {
          this.roomDeleted(room)
        }
      },

      async tap_room_rename(room, el) {
        if (el) {
          var li = el.currentTarget.parentNode.parentNode
          mui.swipeoutClose(li)
        }
        const e = await prompt('Rename this room:', room.name, 'Name', ['CANCEL', 'OK']);
        if (e.index == 1) {
          room.name = e.value.trim()
          this.roomModified(room)
        }
      },

      async tap_room(room) {
        this.$router.push(`/checkroom?house_id=${this.house_id}&room_id=${room.id}`)
      },

      condition(room) {
        return room.components.every(a => !!a.status)? 'Complete' : 'Incomplete'
      },

      complete(room) {
        return room.components.every(a => !!a.status)
      },

      uploaded(room) {
        return room.components.every(a => !a.images || a.images.every(img => img.task_id))
      }
    },

    async mounted() {
      const {house_id} = this.$route.params;
      if (!house_id) {
        this.$router.go(-1);
      }
      this.house = await this.fetchHouseDetails(house_id);

      setTimeout(() => enableScroll('.mui-scroll-wrapper'), 1000)

    },
  }

</script>

<style scoped>

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 9px;
  }

  .mui-scroll-wrapper {
    bottom: 7rem;
  }

  .function {
    height: 3.5rem;
    margin: 4px;
    flex: 1;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .active {
    color: green;
  }

</style>
