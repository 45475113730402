import Compressor from "compressorjs";

export function generateUID() {
  var firstPart = (Math.random() * 46656) | 0
  var secondPart = (Math.random() * 46656) | 0
  firstPart = ('000' + firstPart.toString(36)).slice(-3)
  secondPart = ('000' + secondPart.toString(36)).slice(-3)
  return firstPart + secondPart
}

export async function compressFile(f) {
  return new Promise((resolve, reject) => {
    new Compressor(f, {
      checkOrientation: true,
      quality: 0.6,
      rotate: 0,
      format: 'jpg',
      maxWidth: '640',
      height: 'auto',
      success: resolve,
      error: reject
    })
  })
}

export async function readAsDataURL(inputFile) {
  const temporaryFileReader = new FileReader()
  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }
    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result)
    }
    temporaryFileReader.readAsDataURL(inputFile)
  })
}

