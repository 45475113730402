import Vue from "vue";
import Vuex from "vuex";
import Accounts from '@/store/Accounts'
import Tasks from '@/store/Tasks'
import HouseList from '@/store/HouseList'
import createPersistedState from 'vuex-persistedstate';
import localforage from 'localforage'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Accounts,
    HouseList,
    Tasks,
  },
  // plugins: [createPersistedState()],
  // plugins: [createPersistedState(localforage.createInstance({
  //   name: "vuex-inspector",
  //   version : 2.0,
  //   storeName : 'mystorageobj'
  // }))],
});
