import Vue from 'vue'
import vuetify from 'vuetify'
// import '@/styles/app.styl'

Vue.use(vuetify, {
  iconfont: 'md',
  options: {
    customProperties: true
  },
  theme: {
    primary: '#2484C6',
  },
})

