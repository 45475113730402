<style scoped>
  .mui-scroll-wrapper {
    bottom: 3.7rem;
  }

</style>

<template>
  <div id="page" v-if="account && account.company">
    <header class="mui-bar mui-bar-nav">
      <h1 class="mui-title">Settings {{version}}</h1>
    </header>

    <div class="mui-content">
      <div class="mui-scroll-wrapper">
        <div class="mui-scroll">
          <div class="mui-content-padded">
            <div class="mui-row">
              <span style="margin: .5rem;" class="mui-pull-left mui-badge mui-btn-grey">Accounts</span>
            </div>

            <ul class="mui-table-view">
              <li class="mui-table-view-cell" style="line-height: 42px" v-on:tap="tap_company_logo()">
                <img class="mui-pull-left mui-media-object" :src="logo">
                <div class="mui-pull-left">{{account.company.company_name}}</div>
                <div class="mui-pull-right">{{account.account}}</div>
              </li>
      				<li class="mui-table-view-cell" v-on:tap='tap_phone()'>
                <div class="mui-pull-left">Phone</div>
      					<p class="mui-pull-right">{{account.phone}}</p>
      				</li>
      				<li class="mui-table-view-cell" v-on:tap='tap_email()'>
                <div class="mui-pull-left">Email</div>
      					<p class="mui-pull-right">{{account.email}}</p>
      				</li>
            </ul>

            <div class="mui-row">
              <span style="margin: .5rem;" class="mui-pull-left mui-badge mui-btn-grey">Inspection</span>
            </div>
            <ul class="mui-table-view">
              <li class="mui-table-view-cell" v-on:tap="tap_show_finished()">
                 <span class="mui-pull-left">Show finished tasks</span>
                 <p class="mui-pull-right">{{showfinishedLabel}}</p>
              </li>
              <li class="mui-table-view-cell" v-on:tap="tap_onlyMe()" v-if="isPM">
                <span class="mui-pull-left">Show my houses only</span>
                <p class="mui-pull-right">{{onlyMyHousesLabel}}</p>
              </li>
              <li class="mui-table-view-cell" v-on:tap="tap_onlyMyWorklist()">
                <span class="mui-pull-left">Show my tasks only</span>
                <p class="mui-pull-right">{{onlyMyWorklistLabel}}</p>
              </li>
              <li class="mui-table-view-cell" v-on:tap="tap_onlyMyCreatedWorklist()">
                <span class="mui-pull-left">Show tasks created by me only</span>
                <p class="mui-pull-right">{{onlyMyCreatedWorklistLabel}}</p>
              </li>
            </ul>
          </div>
        </div>

        <div class="mui-text-center" style="position: absolute; width: 100%; bottom: 3rem; padding: 6px;">
          <a style="color: black; width: 100%; font-size: 12px;" class="mui-btn mui-btn-link" v-on:tap='tap_signup()'>Sign up new account</a>
        </div>

        <div class="mui-text-center" style="position: absolute; width: 100%; bottom: .1rem; padding: 6px;">
          <a style="width: 100%;" class="mui-btn mui-btn-red mui-action-preview" v-on:tap="tap_logout()">Logout</a>
        </div>

      </div>

    </div>

    <BottomBar actived='/settings' />
  </div>
</template>

<script>

  import {toast, alert, confirm, prompt, enableScroll } from "@/assets/js/api";
  import BottomBar from "@/views/bottomBar";
  import { mapGetters, mapActions, mapMutations} from 'vuex'
  import { version } from '/package.json'

  export default {
    name: "Account",
    components: { BottomBar },
    data() {
      return {
        logo: '../../img/logo.png',
        settings: '../../img/settings.png',
      }
    },

    computed: {
      ...mapGetters({
        filter: 'Tasks/filter',
        token: 'Accounts/token',
        account: 'Accounts/account',
      }),

      showfinishedLabel() {
        return this.filter.showfinished ? 'Yes' : 'No';
      },
      onlyMyHousesLabel() {
        return this.filter.onlyMe ? 'Yes' : 'No';
      },
      onlyMyWorklistLabel() {
        return this.filter.onlyMyWorklist ? 'Yes' : 'No';
      },
      onlyMyCreatedWorklistLabel () {
        return this.filter.onlyMyCreatedWorklist? 'Yes' : 'No';
      },
      // hotlinkToServerLabel () {
      //   return this.filter.hotlinkToServer? 'Yes' : 'No';
      // },
      isPM() {
        return this.account.role && this.account.role.includes("PropertyManager") >= 0
      },
      version() { return version }
    },

    methods: {
      ...mapActions({
        logout: 'Accounts/signOut'
      }),
      async tap_phone () {
        const e = await prompt('Reset phone number', this.account.phone, 'Phone', ['CANCEL', 'OK'])
        if (e.index == 1) {
          this.account.phone = e.value
        }
      },

      async tap_email () {
        const e = await prompt('Reset email address', this.account.email, 'Email', ['CANCEL', 'OK'])
        if (e.index == 1) {
          this.account.email = e.value
        }
      },

      tap_company_logo() {
        this.$router.push('/about')
      },

      tap_signup() {
        this.$router.push('/signup')
      },

      async tap_logout() {
        const e = await confirm('', 'Logout?', ['NO', 'YES']);
        if (e.index == 1) {
          this.logout()
          this.$router.push('/signin')
        }
      },

      tap_show_finished() {
        this.filter.showfinished = !this.filter.showfinished;
      },

      tap_onlyMe() {
        this.filter.onlyMe = !this.filter.onlyMe;
      },

      tap_onlyMyWorklist() {
        this.filter.onlyMyWorklist = !this.filter.onlyMyWorklist;
      },

      tap_onlyMyCreatedWorklist() {
        this.filter.onlyMyCreatedWorklist = !this.filter.onlyMyCreatedWorklist;
      }
    },

    async mounted() {
      enableScroll('.mui-scroll-wrapper')
      if (!this.token || !this.account) {
        this.$router.push("/signin")
      }
    }
  }

</script>
