
<style scoped>
  #search {
    color: navy;
  }

	.title {
		margin: 35px 15px 10px;
	}

	.title+.content {
		margin: 10px 15px 35px;
		color: #bbb;
		text-indent: 1em;
		font-size: 14px;
		line-height: 24px;
	}

	.iconBig {
		width: 3rem;
		height: 3rem;
	}

  #searchdiv {
    top: 2.6rem;
  }

  .mui-search.mui-active input[type=search] {
    text-align: left;
    color: darkblue;
    background-color: burlywood;
  }

  .mui-badge {
    margin: 0px;
    padding: 4px 10px;
  }

  .account {
    color: white;
    font-size: 12px;
  }

  .mui-scroll-wrapper {
    top: 5.1rem;
    bottom: 3.7rem;
  }

  .list-icon {
    width: 2rem;
    height: 2rem;
  }
</style>

<template>
  <div id="page">
    <header class="mui-bar mui-bar-nav">
      <h1 id="title" class="mui-title">Tasks</h1>
      <a class="mui-icon material-icons mui-pull-right" v-on:tap="tap_refresh()">autorenew</a>
      <div id="searchdiv" class="mui-input-row mui-search">
        <input id="search" type="search" class="mui-input-clear" placeholder="">
      </div>
    </header>
    <div class="mui-content">
      <div class="mui-scroll-wrapper">
        <div class="mui-scroll">
          <div class="mui-text-center" v-if='tasks.length <=0' style="margin-top: 3rem;">
            <svg class="icon" href="#" aria-hidden="true" style="height: 6rem; width: 6rem;">
              <use xlink:href='#icon--wenjian'></use>
            </svg>
            <h4>Loading task list...</h4>
          </div>

          <ul class="mui-table-view" v-else>
            <li class="mui-table-view-cell mui-media mui-collapse" style="padding: 0rem; margin: 0.2rem;" v-for="group in taskGroups" :key="group.label">
              <a class="mui-navigate-right" style="height: 3rem; margin: 0; padding: 1rem;" :style="{color: group.color}">
                <span class="mui-pull-left">{{group.label}}</span>
                <span class="mui-pull-right mui-badge" style="color: red; padding: 0.5rem;">{{group.items? group.items.length: '7 days'}}</span>
              </a>
              <ul class="mui-table-view" v-if="group.items">
                <li class="mui-table-view-cell mui-media" v-for="it in group.items" :key="it.id" v-on:tap="tap_item(it)" :set="h = getHouse(it)"
                              :class="{active: getTask(it.id)}">
                  <div class="mui-slider-right mui-disabled" v-if="archivable(it)">
                    <a class="mui-btn mui-btn-red" v-on:tap.stop='tap_archive(it,$event)'>ARCHIVE</a>
                  </div>
                  <div class="mui-slider-handle">
                    <div class="mui-row" style="margin: 0 .75rem 0 0;">
                      <span class="mui-badge mui-pull-left" style="font-size: large; color: navy; padding: 3px; margin: 3px;">{{it.house_id}}</span>
                      <span class="mui-badge mui-pull-left" v-bind:class="mui_badge_color(it)" style="line-height: 24px; display: absolute; top: 50%;">
                        {{due_datetime(it)}}
                      </span>
                    </div>
                    <div class="mui-row">
                      <span class="mui-pull-left" style="margin: 0.25rem 0.25rem 0.25rem 2.1rem; padding: 0 .25rem; text-align: left;">
                        {{it.description}}
                      </span>
                    </div>
                    <div class="mui-slider-handle" style="margin-top: .1rem;">
                      <svg class="mui-icon list-icon mui-pull-left" aria-hidden="true">
                        <use :xlink:href='taskTypeSVG(it)'></use>
                      </svg>
                      <div class="mui-media-body" style="margin: 0 .75rem 0 2.5rem;" if="h">
                        <span class="mui-ellipsis mui-pull-left" v-bind:style="addressStyle(h)">{{addressText(h)}}</span>
                        <span class="mui-pull-left mui-badge  mui-badge-blue account"  style="margin-left:10px" v-if="h.pm">{{h.pm.account}}</span>
                        <span class="mui-pull-right mui-badge account" v-if="it.assignee" v-bind:class="mui_badge_color(it)">{{it.assignee.account}}</span>
                        <br>
                        <p class="mui-pull-left">{{h.suburb}},{{h.city}}</p>
                        <span class="mui-pull-right mui-badge" :class="keyStatusTextColor(it)" v-if="keyStatusText(it)">{{keyStatusText(it)}}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <BottomBar actived='/todolist' />
  </div>
</template>


<script>

  import mui from "@/assets/js/mui.min";
  import moment from "moment";
  import {toast, confirm, enableScroll } from "@/assets/js/api";
  import {KeyStatus} from "@/helpers/keyStatus";
  import BottomBar from "@/views/bottomBar";
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  export default {
    name: "Todolist",
    components: { BottomBar },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters({
        token: 'Accounts/token',
        account: 'Accounts/account',
        tasks: 'Tasks/todolist',
        getTask: 'Tasks/getTask',
        houseList: 'HouseList/houseList'
      }),

      taskGroups() {
        const ts1weekago = moment().subtract(2, 'weeks').endOf('week').add(1, 'day').unix();
        const tsLastWeekStart = moment().subtract(1, 'weeks').startOf('week').add(1, 'day').unix()
        const tsThisWeekStart = moment().startOf('week').add(1, 'day').unix()
        const tsThisWeekEnd = moment().endOf('week').add(1, 'day').unix()
        const tsAfterThisWeek = moment().add(1, 'weeks').startOf('week').add(1, 'day').unix()

        const groups = [{
          label: 'Previous weeks',
          classes: 'confirming-rerent',
          items: this.tasks.filter(task => task.timestamp <= ts1weekago),
          color: 'black',
        },
        ...this.makeGroups('Last ', 'green', tsLastWeekStart, tsThisWeekStart),
        ...this.makeGroups('This ', 'blue', tsThisWeekStart, tsThisWeekEnd),
        {
          label: 'Next weeks',
          classes: 'confirming-rerent',
          items: this.tasks.filter(task => task.timestamp >= tsAfterThisWeek),
          color: 'purple',
        }];
        // console.log({taskGroups: groups})
        return groups;
      }
    },
    methods: {
      ...mapActions({
        fetchTodoList: 'Tasks/fetchTodoList',
        listAllHouses: 'HouseList/list',
        fetchPerformers: 'Tasks/fetchPerformers',
        fetchThisWeek: 'Tasks/fetchThisWeek',
        archive: 'Tasks/archive',
      }),
      makeGroups(weekName, color, tsStart, tsEnd) {
        const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
        const days = [];
        let start = tsStart
        for (let i = 1; i <= 7; i++) {
          days.push({
            items: [],
            label: new Date(start * 1000).toLocaleDateString("en-NZ", options),
            color
          })
          start = moment(start*1000).add(1, 'days').unix()
        }
        const tasks = this.tasks.filter(task => task.timestamp >= tsStart && task.timestamp < tsEnd)
        for (const dayTask of tasks) {
          const day = moment(dayTask.timestamp * 1000).weekday()
          days[(day+6)%7].items.push(dayTask)
        }
        return days;
      },
      archivable(task) {
        if (!task) return false;
        return true;
        // if (task.title === 'maintenance') {
        //   return true;
        // }
        // if (task.status === 8 || task.status === 9) {
        //   return true;
        // }
        // return false;
      },
      getHouse(task) {
        return task.house
      },
      keyStatus(task) {
        if (!task) return KeyStatus.NO_KEY_INFO
        if (!task.house) return KeyStatus.NOT_AVAILABLE
        if (!task.house.keyNumber) return KeyStatus.NOT_BACKUP
        if (!task.key) return KeyStatus.DIDNOT_BORROW
        if (task.key.state === 0) return KeyStatus.IS_BORROWED
        return KeyStatus.IS_RETURNED
      },
      keyStatusText(task) {
        switch (this.keyStatus(task)) {
          case KeyStatus.NOT_AVAILABLE: return 'NOT AVAILABLE'
          case KeyStatus.NOT_BACKUP: return 'NO BACKUP KEY'
          case KeyStatus.DIDNOT_BORROW: return ''
          case KeyStatus.IS_BORROWED: return 'BORROWED'
          case KeyStatus.IS_RETURNED: return 'RETURNED'
          default: case KeyStatus.NO_KEY_INFO: return ''
        }
      },
      keyStatusTextColor(task) {
        switch (this.keyStatus(task)) {
          case KeyStatus.NOT_AVAILABLE: return 'mui-badge-secondary'
          case KeyStatus.NOT_BACKUP: return 'mui-badge-warning'
          case KeyStatus.DIDNOT_BORROW: return ''
          case KeyStatus.IS_BORROWED: return 'mui-badge-green'
          case KeyStatus.IS_RETURNED: return 'mui-badge-primary'
          default: case KeyStatus.NO_KEY_INFO: return ''
        }
      },

      mui_badge_color(it) {
        switch (it.status) {
          case 0: return 'mui-badge-yellow';
          case 1: return 'mui-badge-green';
          case 2: return 'mui-badge-red';
          case 8: return 'mui-badge-purple';
          case 9: return 'mui-badge-grey';
        }
      },

      taskTypeSVG(it) {
        switch (it.title) {
          case 'inspections': return '#icon-wushuju';
          case 'hhs': return '#icon--wenjian';
          case 'maintenance': return '#icon--suo';
        }
      },

      due_datetime(it) {
        const options = { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' };
        const dt = new Date(it.timestamp * 1000).toLocaleDateString("en-NZ", options)
        return dt;
      },

      addressText(h) {
      	if (!h) {
          console.log('h is null')
          return
      	}
        return h.unit && h.num ? `${h.unit}/${h.num} ${h.street}` :
          h.unit ? `${h.unit} ${h.street}` :
            `${h.num} ${h.street}`;
      },

      addressStyle(h) {
        return { color: 'navy' };
      },

      tap_item(it) {
        this.$router.push(`/taskboard?task_id=${it.id}&back=todolist`)
      },

      validateAzFormat(azFormat) {
        if (!azFormat || azFormat.materialCost < 0 || azFormat.laborCost < 0) {
          return false;
        }
        if (azFormat.materialCost > 0 || azFormat.laborCost > 0) {
          return true;
        }
        return false
      },

      validateOtherFormat(otherFormat) {
        if (!otherFormat || otherFormat.claimsFromHim < 0 || otherFormat.claimsToOwner < 0) {
          return false;
        }
        if (otherFormat.claimsFromHim > 0 || otherFormat.claimsToOwner > 0) {
          return true;
        }
        return false
      },

      claimsComplete(claims) {
        return claims.noCost || this.validateAzFormat(claims.azFormat) || this.validateOtherFormat(claims.otherFormat)
      },

      async tap_archive(it, el) {
        if (el) {
          var li = el.currentTarget.parentNode.parentNode
          mui.swipeoutClose(li)
        }
        if (it.key && it.key.state === 0) {
          toast(`PLEASE RETURN KEY FIRST`)
          return;
        }
        if (it.title === 'maintenance') {
          let problem = ''
          if (it.status < 1) {
            problem = `YOU HAVEN'T START THE TASK`
          }
          else if (!it.claims) {
            problem = `YOU HAVEN'T CLAIM EXPENSES`
          } else {
            const claims = JSON.parse(it.claims)
            if (!this.claimsComplete(claims)) {
              problem = `CLAIM EXPENSES NOT COMPLETED`
            }
          }
          if (problem) {
            const res = await confirm('Are you sure to archive tasks anyway?', problem, ['NO', 'YES'])
            if (res.index === 0) {
              return;
            }
          }
        }

        if (it.status === 8) {
          try {
            const res = await confirm('Are you sure to archive tasks without reports?', 'Archive', ['NO', 'YES'],)
            if (res.index === 0) {
              return;
            }
          } catch (error) {
            return;
          }
        }

        this.archive(it)
      },

      async tap_refresh() {
        const res = await confirm('Refresh will blow away all unsaved data.  Continue?', 'Refresh', ['NO', 'YES'],)
        if (res.index === 1) {
          await this._refresh()
        }
      },

      async _refresh() {
        // await this.$localforage.clear()
        await this.fetchTodoList()
        // await this.listAllHouses()
        await this.fetchPerformers()
        // await this.fetchThisWeek()
      }
    },

    async mounted() {
      if (!this.token || !this.account) {
        this.$router.push("/signin")
      }
      this._refresh()
      // if (!this.tasks || !this.tasks.length) {
      //   this._refresh()
      // }
      enableScroll('.mui-scroll-wrapper')
    }
  }

</script>
