<template>
  <div class="mui-table-view-cell">
    <a class="mui-row mui-navigate-right" href="#" style="margin-bottom: 1.3rem;">
      <span class="mui-badge" style="left: .2rem;">Claim expenses (+GST)</span>
    </a>
    <div class="mui-collapse-content" style="margin: .2rem; padding: 0.2rem;">
      <div class="mui-input-row mui-checkbox">
        <label class="mui-pull-right" style="font-weight: 700; color:darkred">
          <input :name="NoCost" type="checkbox" v-model="claimNoCost">
          <span class="mui-pull-right">Claim no cost</span>
        </label>
       </div>
      <div class="mui-row cost-group" v-if="claims && claims.azFormat">
        <div class="mui-row cost-group-title" >
          <span>From AZ group </span>
        </div>
        <div class="mui-row cost-group-content">
          <div class="mui-input-row" style="margin: .4rem;">
            <input style="width: 59%; background-color:lightyellow" type="text" placeholder="Material" v-model="claims.azFormat.materialDescription">
            $ <input style="width: 33%; background-color:lightyellow; text-align: right; " type="number" placeholder="NZD" v-model="claims.azFormat.materialCost">
          </div>
          <div class="mui-input-row" style="margin: .4rem;">
            <input style="width: 59%; background-color:lightyellow" type="text" placeholder="Labor cost" v-model="claims.azFormat.laborDescription">
            $ <input style="width: 33%; background-color:lightyellow; text-align: right;" type="number" placeholder="NZD" v-model="claims.azFormat.laborCost">
          </div>
        </div>
      </div>
      <br/>

      <div class="mui-row cost-group" v-if="claims && claims.otherFormat">
        <div class="mui-row cost-group-title" style="background-color: lightblue;">
          <span>From outsources</span>
        </div>
        <div class="mui-row cost-group-content">
          <div class="mui-input-row" style="margin: .4rem;">
            <span class="mui-pull-left">Handyman:</span>
            <input class="mui-pull-right" style="width: 50%; background-color:lightyellow; text-align: right; " type="text" placeholder="Name" v-model="claims.otherFormat.handyman">
          </div>
          <div class="mui-input-row" style="margin: .4rem;">
            <span class="mui-pull-left">Claims from him:</span>
            <input class="mui-pull-right" style="width: 33%; background-color:lightyellow; text-align: right; " type="number" placeholder="NZD" v-model="claims.otherFormat.claimsFromHim">
            <span class="mui-pull-right">$</span>
          </div>
          <div class="mui-input-row" style="margin: .4rem;">
            <span class="mui-pull-left">Claims to owner:</span>
            <input class="mui-pull-right" style="width: 33%; background-color:lightyellow; text-align: right; " type="number" placeholder="NZD" v-model="claims.otherFormat.claimsToOwner">
            <span class="mui-pull-right">$</span>
          </div>
        </div>
      </div>
      <br/>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: "ClaimExpanse",
    props: {
      task: {
        type: Object,
        required: true,
        validator: v => v.house_id && v.timestamp && v.title
      },
    },
    data() {
      return {
        NoCost: 'NoCost',
        activeIndex: -1,
        busy: false,
        claims: {
          azFormat: {
            materialDescription: '',
            materialCost: '',
            laborDescription: '',
            laborCost: '',
          },
          otherFormat: {
            handyman: '',
            description: '',
            claimsFromHim: '',
            claimsToOwner: '',
          },
          noCost: false,
        },
      }
    },
    computed: {
      claimNoCost: {
        get() {
          return !this.claims || (this.claims.noCost);
        },
        set(v) {
          this.claims.noCost = v;
          if (v) {
            this.claims = {
              azFormat: {
                materialDescription: '',
                materialCost: '',
                laborDescription: '',
                laborCost: '',
              },
              otherFormat: {
                handyman: '',
                description: '',
                claimsFromHim: '',
                claimsToOwner: '',
              },
              noCost: true,
            }
          }
        }
      },
    },
    methods: {
      ...mapActions({
        updateClaims: 'Tasks/updateClaims',
      }),

      validateAzFormat(azFormat) {
        if (!azFormat || azFormat.materialCost < 0 || azFormat.laborCost < 0) {
          return false;
        }
        if (azFormat.materialCost > 0 || azFormat.laborCost > 0) {
          return true;
        }
        return false
      },

      validateOtherFormat(otherFormat) {
        if (!otherFormat || otherFormat.claimsFromHim < 0 || otherFormat.claimsToOwner < 0) {
          return false;
        }
        if (otherFormat.claimsFromHim > 0 || otherFormat.claimsToOwner > 0) {
          return true;
        }
        return false
      },

      validate(claims) {
        return this.claims.noCost || this.validateAzFormat(claims.azFormat) || this.validateOtherFormat(claims.otherFormat)
      },

      async save_claims() {
        this.updateClaims(this.claims)
      },
    }
  }



</script>

<style scoped>

  .cost-group {
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 3px;
  }

  .cost-group-title {
    width: 100%;
    background-color:lightgreen;
    text-align: center;
  }

  .cost-group-content {
    padding: 0.2rem;
  }

</style>